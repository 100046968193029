import React from 'react'
import Header from '../components/Header'
import { ShopHeader } from '../components/HeaderComponents/ShopHeader'
import Image from '../components/HTMLComponents/Image'
import InfosSection from '../components/InfosSection'

import ownerImage from '../assets/owner-website.png'
import '../css/pages/Infos.css'

/**
 * Component of the infos page
 * @returns {JSX.Element} Render the infos page
 */
const Infos = () => {

    let classname = 'infos'

    // Change CSS of this page because it's fixed
    if(window.location.pathname === '/infos')
        classname += ' fixed_main'

    const expos = [
        {id: 1, year: 2022, name: "FRIPERIGOLOTE", location: "21290 Recey sur Ource"},
        {id: 2, year: 2021, name: "LES Z'UNS POSSIBLE", location: "21400 Chamesson"},
        {id: 3, year: 2020, name: "CENTRE SOCIO CULTUREL", location: "21400 Chatillon sur Seine"}
    ]

    const publications = [
        {id: 1, year: 2023, name: "ATELIER LA MALLETTE"},
        {id: 2, year: 2018, name: "ARIGATO GOZAIMASU"},
    ]

    return (
        <>
            <Header />
            <main className={classname}>
                <section className='infos-owner'>
                    <Image id={1} path={ownerImage} alt={"Autoportrait de Lucie DECK"} title={"Lucie DECK"} classname={"infos-owner-image"} />
                    <h1>Lucie DECK</h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro rem assumenda voluptates soluta temporibus nulla, fugiat laudantium! Cupiditate accusantium laboriosam dignissimos, quibusdam temporibus expedita quisquam, ab iure, magni eveniet aliquam.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint dolorem similique libero ab possimus ad qui fugiat totam deserunt? Eveniet necessitatibus repellat distinctio obcaecati perspiciatis. Excepturi natus obcaecati numquam laudantium.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti nisi maxime accusamus ad. Alias optio explicabo ipsam nostrum, aperiam obcaecati? Et fugiat est repellat rerum laborum sed, assumenda culpa laudantium?
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora, iure? Reiciendis eum ipsa, veniam at soluta adipisci? Cupiditate, quos ratione libero suscipit voluptatibus expedita facilis nihil provident cumque, tempore tenetur.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel, sequi atque sunt quibusdam, suscipit optio molestias odio voluptate quo ut, omnis recusandae voluptatum. Dicta laborum harum quia dolor, officiis dolore!
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur sed voluptates error quo, quia ab iure ea delectus mollitia repellendus consequuntur fugiat, molestiae illo dolorem animi deserunt aliquid maiores officia!
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo iure perspiciatis reprehenderit veniam! Dolore nesciunt voluptates omnis cum quibusdam perspiciatis tempora laborum a eos, fugit dolor quasi expedita? Vero, eveniet.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi repudiandae non inventore nemo dicta aut. Aut incidunt, odit nostrum alias praesentium perferendis quia, rerum voluptate ea iusto debitis in et!
                    </p>
                </section>
                <InfosSection title={"Expositions"} datas={expos} />
                <InfosSection title={"Publications"} datas={publications} />
            </main>
            <ShopHeader />
        </>
    )
}

export default Infos