import React, { useState, useEffect } from 'react';

import '../../css/components/AdminComponents/ProductsModal.css';

const ProductsModal = ({ associatedProducts, allProducts, onClose, onConfirm }) => {
    const [leftProducts, setLeftProducts] = useState([]);
    const [rightProducts, setRightProducts] = useState([]);
    const [draggedProduct, setDraggedProduct] = useState(null);

    useEffect(() => {
        setLeftProducts(associatedProducts || []);
        const associatedIds = (associatedProducts || []).map((p) => p.id);
        const unassociatedProducts = allProducts.filter((p) => !associatedIds.includes(p.id));
        setRightProducts(unassociatedProducts);
    }, [associatedProducts, allProducts]);

    const handleDragStart = (e, product, fromLeft) => {
        setDraggedProduct({ product, fromLeft });
    };

    const handleDrop = (e, targetLeft, targetIndex = null) => {
        e.preventDefault();
        const { product, fromLeft } = draggedProduct;

        if (fromLeft && !targetLeft) {
            // Déplacer de gauche à droite
            setLeftProducts(leftProducts.filter((p) => p.id !== product.id));
            setRightProducts([...rightProducts, product]);
        } else if (!fromLeft && targetLeft) {
            // Déplacer de droite à gauche
            setRightProducts(rightProducts.filter((p) => p.id !== product.id));
            setLeftProducts([...leftProducts, product]);
        } else if (fromLeft && targetLeft && targetIndex !== null) {
            // Réorganiser dans la liste de gauche
            const updatedLeft = [...leftProducts.filter((p) => p.id !== product.id)];
            updatedLeft.splice(targetIndex, 0, product);
            setLeftProducts(updatedLeft);
        }

        setDraggedProduct(null);
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleConfirm = () => {
        onConfirm(leftProducts);
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les produits</h2>
                <div className="modal-body">
                    <div
                        className="product-list left"
                        onDragOver={allowDrop}
                        onDrop={(e) => handleDrop(e, true)}
                    >
                        <h3>Produits associés</h3>
                        <div className="products-container">
                            {leftProducts.map((product, index) => (
                                <div
                                    key={product.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, product, true)}
                                    onDrop={(e) => handleDrop(e, true, index)}
                                    onDragOver={allowDrop}
                                    className="product-item"
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="product-list right"
                        onDragOver={allowDrop}
                        onDrop={(e) => handleDrop(e, false)}
                    >
                        <h3>Produits disponibles</h3>
                        <div className="products-container">
                            {rightProducts.map((product) => (
                                <div
                                    key={product.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, product, false)}
                                    className="product-item"
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default ProductsModal;
