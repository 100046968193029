import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/check-auth`, {
                    method: 'GET',
                    credentials: 'include', // Assure l'envoi des cookies HTTPOnly
                });

                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);
            } catch (error) {
                console.error('Erreur lors de la vérification de l\'authentification', error);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    // En attente de la vérification
    if (isAuthenticated === null) {
        return <div>Chargement...</div>;  // Affiche un loader pendant la vérification
    }

    // Si non authentifié, redirige vers la page de connexion
    if (!isAuthenticated) {
        return <Navigate to="/admin" replace />;
    }

    // Si authentifié, afficher le contenu protégé
    return children;
};

export default ProtectedRoute;
