import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/pages/admin/Users.css';

const Users = () => {
    const [users, setUsers] = useState([]); // Liste des utilisateurs
    const [name, setName] = useState(''); // Champ name
    const [password, setPassword] = useState(''); // Champ password
    const [editIndex, setEditIndex] = useState(null); // Pour gérer la mise à jour d'un utilisateur existant
    const [loading, setLoading] = useState(true); // État de chargement
    const [error, setError] = useState(null); // État d'erreur

    // Fonction pour récupérer les utilisateurs depuis une API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/users`); // Remplace par l'URL réelle de ton API
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des utilisateurs');
                }
                const data = await response.json();
                setUsers(data); // On suppose que l'API renvoie un tableau d'utilisateurs
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    // Fonction pour ajouter un utilisateur via une API
    const handleAddUser = async (newUser) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/users/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUser),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de l\'utilisateur');
            }

            const createdUser = await response.json();
            setUsers([...users, createdUser]);
        } catch (err) {
            setError(err.message);
        }
    };

    // Fonction pour mettre à jour un utilisateur via une API
    const handleUpdateUser = async (index, updatedUser) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const userId = users[index].id;
            const response = await fetch(`${apiUrl}/users/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de l\'utilisateur');
            }

            const updatedUsers = [...users];
            updatedUsers[index] = { ...users[index], ...updatedUser }; // Mise à jour locale
            setUsers(updatedUsers);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    // Fonction pour supprimer un utilisateur via une API
    const handleDeleteUser = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const userId = users[index].id;
            const response = await fetch(`${apiUrl}/users/${userId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'utilisateur');
            }
    
            const updatedUsers = users.filter((_, i) => i !== index);
            setUsers(updatedUsers);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshUsers = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/users`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des utilisateurs');
            const data = await response.json();
            setUsers(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des utilisateurs :', err);
        }
    };

    // Fonction pour gérer la soumission du formulaire (ajout ou mise à jour)
    const handleSubmit = async (e) => {
        e.preventDefault();

        const newUser = { name, password };

        if (editIndex !== null) {
            // Mise à jour de l'utilisateur
            await handleUpdateUser(editIndex, newUser);
        } else {
            // Ajout d'un nouvel utilisateur
            await handleAddUser(newUser);
        }

        await refreshUsers();

        // Réinitialiser les champs après ajout ou mise à jour
        setName('');
        setPassword('');
        setEditIndex(null);
    };

    // Fonction pour éditer un utilisateur
    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setEditIndex(null);
            setName('');
            setPassword('');
        } else {
            const user = users[index];
            setName(user.name || ''); // Valeur par défaut pour éviter undefined
            setPassword(user.password || ''); // Valeur par défaut pour éviter undefined
            setEditIndex(index);
        }
    };

    if (loading) {
        return <div>Chargement des utilisateurs...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="users">
            <div>
                <h1>Gestion des utilisateurs</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''} // Valeur par défaut
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            value={password || ''} // Valeur par défaut
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des utilisateurs</h2>
                <ul>
                    {users.map((user, index) => (
                        <li key={`${user.id}-${index}`}>
                            <div
                                className="userDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <span>{user.name}</span> | <span>{user.password}</span>
                            </div>
                            <button onClick={() => handleDeleteUser(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Users;
