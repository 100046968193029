import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import CollectionsModal from '../../components/AdminComponents/CollectionsModal';
import ProductsModal from '../../components/AdminComponents/ProductsModal';

import '../../css/pages/admin/Series.css';

const Series = () => {
    const [series, setSeries] = useState([]);
    const [title, setTitle] = useState('');
    const [collection, setCollection] = useState(null); // Collection associée
    const [allCollections, setAllCollections] = useState([]); // Toutes les collections disponibles
    const [products, setProducts] = useState([]); // Produits associés à la série
    const [allProducts, setAllProducts] = useState([]); // Tous les produits disponibles
    const [showCollectionsModal, setShowCollectionsModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Récupérer les séries avec leurs collections et produits associés
    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/series/details`); // Endpoint qui renvoie les séries avec collections et produits
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des séries');
                }
                const data = await response.json();
                setSeries(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchSeries();
    }, []);

    // Récupérer toutes les collections disponibles
    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/collections`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des collections');
                }
                const data = await response.json();
                setAllCollections(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchCollections();
    }, []);

    // Récupérer tous les produits disponibles
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/products`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des produits');
                }
                const data = await response.json();
                setAllProducts(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchProducts();
    }, []);

    const handleAddSeries = async (newSeries) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/series/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newSeries),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'ajout de la série');
            }

            const createdSeries = await response.json();
            setSeries([...series, { id: createdSeries.seriesId, ...newSeries }]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateSeries = async (index, updatedSeries) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const seriesId = series[index].id;
            const response = await fetch(`${apiUrl}/series/${seriesId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedSeries),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de la série');
            }

            const updatedSeriesList = [...series];
            updatedSeriesList[index] = { ...series[index], ...updatedSeries };
            setSeries(updatedSeriesList);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteSeries = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const seriesId = series[index].id;
            const response = await fetch(`${apiUrl}/series/${seriesId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression de la série');
            }

            const updatedSeries = series.filter((_, i) => i !== index);
            setSeries(updatedSeries);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshSeries = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/series/details`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des séries');
            const data = await response.json();
            setSeries(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des séries :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newSeries = {
            title,
            id_collection: collection?.id || null,
            products: products.map(product => ({
                id_product: product.id
            })),
        };

        if (editIndex !== null) {
            // Mise à jour de la série
            await handleUpdateSeries(editIndex, newSeries);
        } else {
            // Ajout d'une nouvelle série
            await handleAddSeries(newSeries);
        }

        await refreshSeries();

        // Réinitialiser les champs après ajout ou mise à jour
        setTitle('');
        setCollection(null);
        setProducts([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            // Annuler l'édition
            setEditIndex(null);
            setTitle('');
            setCollection(null);
            setProducts([]);
        } else {
            // Éditer la série sélectionnée
            const serie = series[index];
            setTitle(serie.title || '');
            setCollection(serie.collection || null);
            setProducts(serie.products || []);
            setEditIndex(index);
        }
    };

    // Gestion des modales
    const openCollectionsModal = () => {
        setShowCollectionsModal(true);
    };

    const closeCollectionsModal = () => {
        setShowCollectionsModal(false);
    };

    const handleCollectionChange = (selectedCollection) => {
        setCollection(selectedCollection);
        setShowCollectionsModal(false);
    };

    const openProductsModal = () => {
        setShowProductsModal(true);
    };

    const closeProductsModal = () => {
        setShowProductsModal(false);
    };

    const handleProductsChange = (selectedProducts) => {
        setProducts(selectedProducts);
    };

    if (loading) {
        return <div>Chargement des séries...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_series">
            <div>
                <h1>Gestion des séries</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="title">Titre</label>
                        <input
                            type="text"
                            id="title"
                            value={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button type="button" onClick={openCollectionsModal}>Gérer la collection</button>
                        <span>{collection ? collection.name : 'Aucune collection sélectionnée'}</span>
                    </div>
                    <div>
                        <button type="button" onClick={openProductsModal}>Gérer les produits</button>
                        <span>{products.length} produit(s) sélectionné(s)</span>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des séries</h2>
                <ul>
                    {series.map((serie, index) => (
                        <li key={`${serie.id}-${index}`}>
                            <div
                                className="adminSeriesDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <h3>{serie.title}</h3>
                                <p>Collection : {serie.collection?.name || 'Aucune'}</p>
                                <p>{serie.products.length} produit(s)</p>
                            </div>
                            <button onClick={() => handleDeleteSeries(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>

            {showCollectionsModal && (
                <CollectionsModal
                    selectedCollection={collection}
                    allCollections={allCollections}
                    onCancel={closeCollectionsModal}
                    onConfirm={handleCollectionChange}
                />
            )}

            {showProductsModal && (
                <ProductsModal
                    associatedProducts={products}
                    allProducts={allProducts}
                    onClose={closeProductsModal}
                    onConfirm={handleProductsChange}
                />
            )}
        </section>
    );
};

export default Series;
