// Products.jsx

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PicturesModal from '../../components/AdminComponents/PicturesModal';
import FormatsModal from '../../components/AdminComponents/FormatsModal';

import '../../css/pages/admin/Products.css';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [priceHT, setPriceHT] = useState('');
    const [priceTTC, setPriceTTC] = useState('');
    const [taxe, setTaxe] = useState('');
    const [formats, setFormats] = useState([]); // Formats associés au produit
    const [allFormats, setAllFormats] = useState([]); // Tous les formats disponibles
    const [pictures, setPictures] = useState([]); // Images associées au produit avec display_order
    const [allPictures, setAllPictures] = useState([]); // Toutes les images disponibles
    const [showFormatsModal, setShowFormatsModal] = useState(false);
    const [showPicturesModal, setShowPicturesModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Récupérer les produits avec leurs formats et images associées
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/products/details`); // Endpoint qui renvoie les produits avec images et formats
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des produits');
                }
                const data = await response.json();
                setProducts(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    // Récupérer toutes les images disponibles
    useEffect(() => {
        const fetchPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/pictures`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des images');
                }
                const data = await response.json();
                setAllPictures(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchPictures();
    }, []);

    // Récupérer tous les formats disponibles
    useEffect(() => {
        const fetchFormats = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/formats`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des formats');
                }
                const data = await response.json();
                setAllFormats(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchFormats();
    }, []);

    const handleAddProduct = async (newProduct) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/products/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newProduct),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'ajout du produit');
            }

            const createdProduct = await response.json();
            setProducts([...products, { id: createdProduct.productId, ...newProduct }]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateProduct = async (index, updatedProduct) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const productId = products[index].id;
            const response = await fetch(`${apiUrl}/products/${productId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProduct),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour du produit');
            }

            const updatedProducts = [...products];
            updatedProducts[index] = { ...products[index], ...updatedProduct };
            setProducts(updatedProducts);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteProduct = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const productId = products[index].id;
            const response = await fetch(`${apiUrl}/products/${productId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression du produit');
            }

            const updatedProducts = products.filter((_, i) => i !== index);
            setProducts(updatedProducts);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshProducts = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/products/details`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des produits');
            const data = await response.json();
            setProducts(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des produits :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newProduct = {
            name,
            description,
            price_ht: parseFloat(priceHT),
            price_ttc: parseFloat(priceTTC),
            taxe: parseFloat(taxe),
            formats: formats.map(format => ({
                id_format: format.id,
                stock: format.stock || 0,
            })),
            pictures: pictures.map((picture, index) => ({
                id: picture.id,
                display_order: index
            })),
        };

        if (editIndex !== null) {
            // Mise à jour du produit
            await handleUpdateProduct(editIndex, newProduct);
        } else {
            // Ajout d'un nouveau produit
            await handleAddProduct(newProduct);
        }

        await refreshProducts();

        // Réinitialiser les champs après ajout ou mise à jour
        setName('');
        setDescription('');
        setPriceHT('');
        setPriceTTC('');
        setTaxe('');
        setFormats([]);
        setPictures([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            // Annuler l'édition
            setEditIndex(null);
            setName('');
            setDescription('');
            setPriceHT('');
            setPriceTTC('');
            setTaxe('');
            setFormats([]);
            setPictures([]);
        } else {
            // Éditer le produit sélectionné
            const product = products[index];
            setName(product.name || '');
            setDescription(product.description || '');
            setPriceHT(product.price_ht || '');
            setPriceTTC(product.price_ttc || '');
            setTaxe(product.taxe || '');
            setFormats(product.formats || []);
            setPictures(product.pictures.sort((a, b) => a.display_order - b.display_order) || []);
            setEditIndex(index);
        }
    };

    // Gestion des modales
    const openPicturesModal = () => {
        setShowPicturesModal(true);
    };

    const closePicturesModal = () => {
        setShowPicturesModal(false);
    };

    const handlePicturesChange = (selectedPictures) => {
        setPictures(selectedPictures);
    };

    const openFormatsModal = () => {
        setShowFormatsModal(true);
    };

    const closeFormatsModal = () => {
        setShowFormatsModal(false);
    };

    const handleFormatsChange = (selectedFormats) => {
        setFormats(selectedFormats);
    };

    if (loading) {
        return <div>Chargement des produits...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_products">
            <div>
                <h1>Gestion des produits</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label htmlFor="priceHT">Prix HT</label>
                        <input
                            type="number"
                            id="priceHT"
                            value={priceHT || ''}
                            onChange={(e) => setPriceHT(e.target.value)}
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="priceTTC">Prix TTC</label>
                        <input
                            type="number"
                            id="priceTTC"
                            value={priceTTC || ''}
                            onChange={(e) => setPriceTTC(e.target.value)}
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="taxe">Taxe</label>
                        <input
                            type="number"
                            id="taxe"
                            value={taxe || ''}
                            onChange={(e) => setTaxe(e.target.value)}
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <button type="button" onClick={openFormatsModal}>Gérer les formats</button>
                        <span>{formats.length} format(s) sélectionné(s)</span>
                    </div>
                    <div>
                        <button type="button" onClick={openPicturesModal}>Gérer les images</button>
                        <span>{pictures.length} image(s) sélectionnée(s)</span>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des produits</h2>
                <ul>
                    {products.map((product, index) => (
                        <li key={`${product.id}-${index}`}>
                            <div
                                className="adminProductDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <h3>{product.name}</h3>
                                <p>{product.description}</p>
                                <p>Prix HT: {product.price_ht}</p>
                                <p>Prix TTC: {product.price_ttc}</p>
                                <p>Taxe: {product.taxe}</p>
                            </div>
                            <button onClick={() => handleDeleteProduct(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>

            {showPicturesModal && (
                <PicturesModal
                    associatedPictures={pictures}
                    allPictures={allPictures}
                    onClose={closePicturesModal}
                    onConfirm={handlePicturesChange}
                />
            )}

            {showFormatsModal && (
                <FormatsModal
                    associatedFormats={formats}
                    allFormats={allFormats}
                    onClose={closeFormatsModal}
                    onConfirm={handleFormatsChange}
                />
            )}
        </section>
    );
};

export default Products;
