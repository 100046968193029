import React, { useState, useEffect } from 'react';

import Header from '../components/Header';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';
import { CartModalShop } from '../components/ShopComponents/CartModalShop';
import { ShopImage } from '../components/ShopComponents/ShopImage';

import '../css/pages/Shop.css';

/**
 * Composant qui affiche la page "Shop" du site web
 * @returns {JSX.Element}
 */
export const Shop = () => {

  // État pour déterminer si la modale du panier est affichée ou non
  const [isModalCartDisplay, setIsModalCartDisplay] = useState(false);
  // État qui liste tous les articles du panier
  const [items, setItems] = useState([]);
  // État pour les collections récupérées depuis le backend
  const [collections, setCollections] = useState([]);
  // État pour les erreurs
  const [error, setError] = useState(null);

  let classname = 'shop';

  // Changer le CSS sur cette page car elle est fixe
  if (window.location.pathname === '/boutique')
    classname += ' fixed_main';

  /**
   * Récupérer les collections depuis le backend
   */
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/collections/details`); // Endpoint qui renvoie les collections avec leurs séries et produits
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des collections');
        }
        const data = await response.json();
        setCollections(data);
      } catch (err) {
        console.error(err.message);
        setError(err.message);
      }
    };
    fetchCollections();
  }, []);

  /**
   * Afficher la modale du panier et supprimer la barre de défilement sur la page principale
   */
  function displayModal() {
    const bodyElement = document.getElementsByTagName("body");
    setIsModalCartDisplay(prev => !prev);
    if (!isModalCartDisplay)
      bodyElement[0].classList.add('stop-overflow');
    else
      bodyElement[0].classList.remove('stop-overflow');
  }

  /**
   * Utiliser le stockage local pour garder en mémoire tous les articles du panier de l'utilisateur
   * @param {Object} product - L'objet produit complet
   * @param {string} format - Format du nouveau produit dans le panier
   */
  function handleModalCart(product, format) {
    if (!product) {
      alert('Produit introuvable.');
      return;
    }

    // Trouver le format sélectionné pour ce produit
    const selectedFormat = product.formats.find((f) => f.name === format);
    const stock = selectedFormat ? selectedFormat.stock : 0;

    if (stock === 0) {
      alert('Ce produit est en rupture de stock.');
      return;
    }

    let cart = [];
    if (window.localStorage.getItem('cart')) {
      cart = JSON.parse(window.localStorage.getItem('cart'));
    }

    // Trouver l'élément correspondant dans le panier
    const cartItemIndex = cart.findIndex(
      (item) => item.id === product.id && item.format === format
    );

    if (cartItemIndex > -1) {
      // Vérifier le stock avant d'ajouter
      if (cart[cartItemIndex].qty + 1 > stock) {
        alert(`Stock insuffisant pour ajouter ce produit. Stock disponible : ${stock}.`);
        return;
      }
      // Mettre à jour la quantité
      cart[cartItemIndex].qty += 1;
    } else {
      // Ajouter un nouvel élément au panier
      cart.push({
        id: product.id,
        format: format,
        qty: 1,
        name: product.name,
        price_ttc: product.price_ttc,
        // Ajoutez d'autres propriétés nécessaires ici
      });
    }

    // Mettre à jour le localStorage et l'état local
    window.localStorage.setItem('cart', JSON.stringify(cart));
    setItems(cart); // Met à jour l'état local avec le panier actualisé
    displayModal();
  }

  return (
    <>
      <Header />
      <main className={classname}>
        {error && <div>Erreur : {error}</div>}

        {collections.map((collection) => (
          <div key={collection.id}>
            {collection.series.map((serie) => (
              <div key={serie.id}>
                <section className="shop_header">
                  <h1>Collection {collection.name}</h1>
                  <h2>Série {serie.title}</h2>
                </section>
                <section className='shop_grid'>
                  {serie.products.map((product) => (
                    <ShopImage
                      key={product.id}
                      id={product.id}
                      path={product.pictures && product.pictures.length > 0 ? product.pictures[0].url : 'default_image_url'}
                      alt={product.name}
                      title={product.name}
                      price={`${product.price_ttc}€`}
                      handleModal={handleModalCart}
                      product={product}
                    />
                  ))}
                </section>
              </div>
            ))}
          </div>
        ))}
        {isModalCartDisplay &&
          <CartModalShop onClickParent={displayModal} cartItems={items} setItems={setItems} />
        }
      </main>
      <ShopHeader />
    </>
  );
};
