// PicturesModal.jsx

import React, { useState, useEffect } from 'react';
import '../../css/components/AdminComponents/PicturesModal.css';
import Image from '../HTMLComponents/Image';

const PicturesModal = ({ associatedPictures, allPictures, onClose, onConfirm }) => {
    const [leftPictures, setLeftPictures] = useState([]);
    const [rightPictures, setRightPictures] = useState([]);
    const [draggedPicture, setDraggedPicture] = useState(null);

    useEffect(() => {
        setLeftPictures(associatedPictures || []);
        const associatedIds = (associatedPictures || []).map(p => p.id);
        const unassociatedPictures = allPictures.filter(p => !associatedIds.includes(p.id));
        setRightPictures(unassociatedPictures);
    }, [associatedPictures, allPictures]);

    const handleDragStart = (e, picture, fromLeft) => {
        setDraggedPicture({ picture, fromLeft });
    };

    const handleDrop = (e, targetLeft, targetIndex = null) => {
        e.preventDefault();
        const { picture, fromLeft } = draggedPicture;

        if (fromLeft && !targetLeft) {
            // Déplacer de gauche à droite
            setLeftPictures(leftPictures.filter(p => p.id !== picture.id));
            setRightPictures([...rightPictures, picture]);
        } else if (!fromLeft && targetLeft) {
            // Déplacer de droite à gauche
            setRightPictures(rightPictures.filter(p => p.id !== picture.id));
            setLeftPictures([...leftPictures, picture]);
        } else if (fromLeft && targetLeft && targetIndex !== null) {
            // Réorganiser dans la liste de gauche
            const updatedLeft = [...leftPictures.filter(p => p.id !== picture.id)];
            updatedLeft.splice(targetIndex, 0, picture);
            setLeftPictures(updatedLeft);
        }

        setDraggedPicture(null);
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleConfirm = () => {
        // Assigner le display_order en fonction de l'ordre dans leftPictures
        const orderedPictures = leftPictures.map((picture, index) => ({
            ...picture,
            display_order: index
        }));
        onConfirm(orderedPictures);
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les images</h2>
                <div className="modal-body">
                    <div
                        className="picture-list left"
                        onDragOver={allowDrop}
                        onDrop={(e) => handleDrop(e, true)}
                    >
                        <h3>Images associées</h3>
                        <div className="pictures-container">
                            {leftPictures.map((picture, index) => (
                                <div
                                    key={picture.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, picture, true)}
                                    onDrop={(e) => handleDrop(e, true, index)}
                                    onDragOver={allowDrop}
                                    className="picture-item"
                                >
                                    <Image
                                        id={picture.id}
                                        path={picture.url}
                                        alt={picture.altText}
                                        title={picture.title}
                                        classname="modal-image"
                                        isFigcaption={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="picture-list right"
                        onDragOver={allowDrop}
                        onDrop={(e) => handleDrop(e, false)}
                    >
                        <h3>Images disponibles</h3>
                        <div className="pictures-container">
                            {rightPictures.map((picture) => (
                                <div
                                    key={picture.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, picture, false)}
                                    className="picture-item"
                                >
                                    <Image
                                        id={picture.id}
                                        path={picture.url}
                                        alt={picture.altText}
                                        title={picture.title}
                                        classname="modal-image"
                                        isFigcaption={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default PicturesModal;
