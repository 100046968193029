import React, { useState, useEffect } from 'react';
import '../../css/components/AdminComponents/CollectionsModal.css';

const CollectionsModal = ({ selectedCollection, onConfirm, onCancel }) => {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [localSelection, setLocalSelection] = useState(selectedCollection || null);

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/collections`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des collections');
                }
                const data = await response.json();
                setCollections(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchCollections();
    }, []);

    const handleSelect = (collection) => {
        setLocalSelection(localSelection?.id === collection.id ? null : collection);
    };

    const handleConfirm = () => {
        onConfirm(localSelection);
        onCancel();
    };

    if (loading) {
        return <div>Chargement des collections...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div className="modal-overlay" onClick={onCancel}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onCancel}>
                    &times;
                </button>
                <h2>Liste des collections</h2>
                <ul>
                    {collections.map((collection) => (
                        <li key={collection.id}>
                            <label>
                                <input
                                    type="radio"
                                    name="collection"
                                    checked={localSelection?.id === collection.id}
                                    onChange={() => handleSelect(collection)}
                                />
                                {collection.name}
                            </label>
                        </li>
                    ))}
                </ul>
                <div className="modal-buttons">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onCancel}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default CollectionsModal;
