import React, { useState, useEffect } from 'react';

import '../../css/components/ShopComponents/Carousel.css';
import Svg from '../HTMLComponents/Svg';
import Image from '../HTMLComponents/Image';

/**
 * Composant qui affiche un carousel avec des flèches et des indicateurs pour naviguer entre les images
 * @param {Object} carousel
 * @param {number} carousel.idProduct - ID du produit dont les images doivent être affichées
 * @param {string} carousel.productTitle - Titre/Nom du produit
 * @returns {JSX.Element} Le composant Carousel
 */
export default function Carousel({ idProduct, productTitle }) {
    // État pour stocker l'index de l'image actuellement affichée
    const [slide, setSlide] = useState(0);
    // État pour stocker les images du produit
    const [images, setImages] = useState([]);
    // État pour gérer les erreurs
    const [error, setError] = useState(null);

    // Récupérer les images du produit depuis l'API
    useEffect(() => {
        const fetchProductPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/products/${idProduct}/details`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des images du produit');
                }
                const data = await response.json();
                // Trier les images par display_order
                const sortedImages = data.pictures.sort((a, b) => a.display_order - b.display_order);
                setImages(sortedImages);
            } catch (err) {
                console.error(err.message);
                setError(err.message);
            }
        };
        fetchProductPictures();
    }, [idProduct]);

    /**
     * Passer à l'image suivante
     */
    const handleClickNextSlide = () => {
        setSlide(slide === images.length - 1 ? 0 : slide + 1);
    };

    /**
     * Revenir à l'image précédente
     */
    const handleClickPrevSlide = () => {
        setSlide(slide === 0 ? images.length - 1 : slide - 1);
    };

    return (
        <div className="carousel">
            {error && <p>Erreur : {error}</p>}
            {!error && images.length > 0 && (
                <>
                    <Svg className='arrow arrow-left' alt='Icône précédent' title="Icône précédent" onClick={handleClickPrevSlide} />
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            classname={slide === index ? "slide" : "slide slide-hidden"}
                            path={image.url}
                            alt={productTitle}
                            title={productTitle}
                            isFigcaption={false}
                            isImageShop={false}
                        />
                    ))}
                    <Svg className='arrow arrow-right' alt='Icône suivant' title="Icône suivant" onClick={handleClickNextSlide} />
                    <span className='carousel-indicators'>
                        {images.map((_, index) => (
                            <button
                                key={index}
                                className={slide === index ? "indicator indicator-active" : "indicator indicator-inactive"}
                                onClick={() => setSlide(index)}
                            ></button>
                        ))}
                    </span>
                </>
            )}
        </div>
    );
}
