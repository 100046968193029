import React from 'react'

import { LogoHeader } from './HeaderComponents/LogoHeader.jsx'
import { NavHeader } from './HeaderComponents/NavHeader.jsx'
import { FilterHeader } from './HeaderComponents/FilterHeader.jsx'
import { SocialsHeader } from './HeaderComponents/SocialsHeader.jsx'
import { Footer } from './Footer.jsx'

import '../css/components/Header.css'

/**
 * Component which display the different pieces of the header
 * @returns {JSX.Element}
 */
export default function Header() {

  let classname = null

  if(window.location.pathname === '/projets' || window.location.pathname === '/activites' || window.location.pathname === '/boutique' || window.location.pathname === '/infos')
    classname = 'fixed'

  if(window.location.pathname === '/panier')
    classname = 'header-cart'

  return (
    <header className={classname}>
        <LogoHeader />
        <NavHeader />
        <FilterHeader />
        <SocialsHeader />
        <Footer />
    </header>
  )
}
