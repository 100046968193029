import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../../css/components/ShopComponents/CartModalShop.css';
import CartItem from './CartItem';
import CartTotalPrice from './CartTotalPrice';

/**
 * Component which render the cart modal on top of the "Shop" page
 * @param {object} cartModalShop
 * @param {function} cartModalShop.onClickParent - Determine if the user click outside the modal
 * @param {object[]} cartModalShop.cartItems - An array of objects which contains all the items inside the users cart
 * @param {number} cartModalShop.cartItems[].id
 * @param {string} cartModalShop.cartItems[].format
 * @param {number} cartModalShop.cartItems[].qty
 * @param {function} cartModalShop.setItems - Modify the local storage with the new list of items
 * @returns {JSX.Element} Render the cart modal
 */
export const CartModalShop = ({ onClickParent, cartItems, setItems }) => {
    const [isModalDisplay, setIsModalDisplay] = useState(false);
    const [products, setProducts] = useState([]); // Products fetched from the backend
    const sectionRef = useRef(null);

    const fetchProducts = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/products/details`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des produits');
            }
            const data = await response.json();
            setProducts(data); // Store the products for CartItem and CartTotalPrice
        } catch (error) {
            console.error('Erreur : ', error);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const handleOnClick = useCallback((event = MouseEvent) => {
        setTimeout(() => {
            setIsModalDisplay(true);
        }, 100);
        if (sectionRef.current && !event.composedPath().includes(sectionRef.current) && isModalDisplay) {
            onClickParent();
        }
    }, [isModalDisplay, onClickParent]);

    const handleOnKeyDown = useCallback((event = KeyboardEvent) => {
        setTimeout(() => {
            setIsModalDisplay(true);
        }, 100);
        if ((event.keyCode === 27 && isModalDisplay) || (event.code === 'Escape' && isModalDisplay)) {
            onClickParent();
        }
    }, [isModalDisplay, onClickParent]);

    useEffect(() => {
        document.body.addEventListener('click', handleOnClick);
        document.body.addEventListener('keydown', handleOnKeyDown);
        return () => {
            document.body.removeEventListener('click', handleOnClick);
            document.body.removeEventListener('keydown', handleOnKeyDown);
        };
    }, [sectionRef, onClickParent, handleOnClick, handleOnKeyDown]);

    return (
        <div>
            <section ref={sectionRef} className="cart-modal-shop">
                <h1 className="title-cart-modal">MON PANIER</h1>
                {cartItems.map((item) => (
                    <CartItem
                        key={cartItems.indexOf(item)}
                        itemId={item.id}
                        format={item.format}
                        qty={item.qty}
                        setItems={setItems}
                        products={products} // Pass the products fetched from the backend
                    />
                ))}
                <hr />
                <CartTotalPrice
                    items={cartItems}
                    isModalCart={true}
                    paymentMethod={'modal'}
                    products={products} // Pass the products fetched from the backend
                />
                <span className="close-modal-cart" onClick={onClickParent}>
                    &times;
                </span>
            </section>
            <div className="cart-modal-div"></div>
        </div>
    );
};
