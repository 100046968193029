import React, { useState, useEffect } from 'react';

const KeywordsModal = ({ selectedKeywords, onConfirm, onCancel }) => {
    const [keywords, setKeywords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [localSelection, setLocalSelection] = useState([...selectedKeywords]);

    useEffect(() => {
        const fetchKeywords = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/keywords`);
                if (!response.ok) {
                    throw new Error("Erreur lors du chargement des mots-clés");
                }
                const data = await response.json();
                setKeywords(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchKeywords();
    }, []);

    const toggleKeyword = (keyword) => {
        if (localSelection.some((k) => k.id === keyword.id)) {
            setLocalSelection(localSelection.filter((k) => k.id !== keyword.id));
        } else {
            setLocalSelection([...localSelection, keyword]);
        }
    };

    if (loading) {
        return <div>Chargement des mots-clés...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div className="modal-content">
            <h2>Liste des mots-clés</h2>
            <ul>
                {keywords.map((keyword) => (
                    <li key={keyword.id}>
                        <label>
                            <input
                                type="checkbox"
                                checked={localSelection.some((k) => k.id === keyword.id)}
                                onChange={() => toggleKeyword(keyword)}
                            />
                            {keyword.name}
                        </label>
                    </li>
                ))}
            </ul>
            <button onClick={() => onConfirm(localSelection)}>Valider</button>
            <button onClick={onCancel}>Annuler</button>
        </div>
    );
};

export default KeywordsModal;
