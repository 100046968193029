import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/components/ShopComponents/CartTotalPrice.css';
import { Link } from 'react-router-dom';

/**
 * Component which render the total price of the cart
 * @param {object} cartTotalPrice
 * @param {Object[]} cartTotalPrice.items - An array of objects which contains all the items inside the users cart
 * @param {boolean} cartTotalPrice.isModalCart - Set to true if this component appears in modal cart
 * @param {number} cartTotalPrice.priceDelivery - Total price of the delivery choose by the user
 * @param {string} cartTotalPrice.paymentMethod - Method of payment wanted by the user in the cart page
 * @param {Object[]} cartTotalPrice.products - Array of all products with their details
 * @returns Render the total price of the cart
 */
export default function CartTotalPrice({ items, isModalCart, priceDelivery, paymentMethod, products }) {
    const [subtotal, setSubtotal] = useState(0);
    const [totalDelivery, setTotalDelivery] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        // Calculer le sous-total en une seule opération
        const newSubtotal = items.reduce((acc, item) => {
            const product = products.find((p) => p.id === item.id);
            if (product) {
                return acc + product.price_ttc * item.qty;
            }
            return acc;
        }, 0);

        setSubtotal(newSubtotal);

        if (!isModalCart) {
            setTotalDelivery(priceDelivery);
            setTotal(newSubtotal + priceDelivery);
        } else {
            setTotal(newSubtotal);
        }
    }, [items, isModalCart, priceDelivery, products]);

    const handleOrder = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/products/stock`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    items
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess(data.message || 'Commande passée avec succès !');
                localStorage.removeItem('cart');
                navigate('/');
                // Optionnel : Réinitialiser le panier, rediriger l'utilisateur, etc.
                // Par exemple, vous pouvez appeler une fonction prop pour vider le panier
            } else {
                setError(data.message || 'Erreur lors du traitement de la commande.');
            }
        } catch (error) {
            console.error('Erreur lors du traitement de la commande :', error);
            setError('Une erreur est survenue lors du traitement de votre commande.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <span className={isModalCart ? 'container-price-modal-cart' : 'container-subprice-cart'}>
                <h2 className='title-price-modal-cart'>SOUS-TOTAL</h2>
                <span className='subtotal-price-modal-cart'>{subtotal.toFixed(2).replace('.', ',')}€</span>
            </span>
            {isModalCart ? null :
                <>
                    {priceDelivery > 0 &&
                        <span className='container-price-delivery'>
                            <h3 className='title-price-delivery'>Livraison</h3>
                            <span className='total-price-delivery'>{totalDelivery.toFixed(2).replace('.', ',')}€</span>
                        </span>
                    }
                    <hr className='hr-cart' />
                    <span className='container-total-cart'>
                        <h1 className='title-total-cart'>TOTAL</h1>
                        <span className='total-price-cart'>{total.toFixed(2).replace('.', ',')}€</span>
                    </span>
                    <p className='taxes-prices-cart'>Taxes de 7,32 € incluses</p>
                </>
            }
            {/* Affichage des messages d'erreur ou de succès */}
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            {loading && <div className="loading-message">Traitement de votre commande...</div>}

            {paymentMethod === 'paypal' ?
                <button className='button-paypal-cart' onClick={handleOrder} disabled={loading}>
                    {loading ? 'Commande en cours...' : 'COMMANDER '}
                    <span>via Paypal</span>
                </button> :
             paymentMethod === 'card' ?
                <button className='button-card-cart' onClick={handleOrder} disabled={loading}>
                    {loading ? 'Commande en cours...' : 'COMMANDER '}
                    <span>par CB</span>
                </button> :
             paymentMethod === 'modal' ?
                <Link to={'/panier'}>
                    <button className='button-price-modal-cart' disabled={loading}>
                        {loading ? 'Commande en cours...' : 'COMMANDER'}
                    </button>
                </Link>
             : null
            }
        </>
    );
}
