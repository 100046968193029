import React, { useState } from 'react';

import '../../css/components/ShopComponents/CartItem.css';
import Image from '../HTMLComponents/Image';

/**
 * Component which render an item of the users cart
 * @param {object} cartItem
 * @param {number} cartItem.itemId - The ID image of the item
 * @param {boolean} cartItem.isModalCart - Set to true if the item is into the modal cart
 * @param {string} cartItem.format - The format of the item
 * @param {number} cartItem.qty - The quantity desired by the user
 * @param {array} cartItem.products - The list of products fetched from the backend
 * @param {function} cartItem.setItems - Return the new list of items after modification by the user
 * @returns {JSX.Element} Render the cart item
 */
export default function CartItem({ itemId, isModalCart, format, qty, setItems, products }) {
    const [stockWarning, setStockWarning] = useState('');
    const product = products.find((p) => p.id === itemId);

    if (!product) {
        return null; // Product not found in backend
    }

    // Find the format stock for the current product
    const selectedFormat = product.formats.find((f) => f.name === format);
    const stock = selectedFormat ? selectedFormat.stock : 0;

    const handleClickMinus = () => {
        const items = JSON.parse(window.localStorage.getItem('cart'));
        items.forEach((item) => {
            if (item.id === itemId && item.format === format) {
                if (item.qty > 1) {
                    item.qty -= 1;
                    setStockWarning(''); // Clear stock warning when decrementing
                } else {
                    items.splice(items.indexOf(item), 1);
                }
            }
        });

        window.localStorage.setItem('cart', JSON.stringify(items));
        setItems(items);
    };

    const handleClickPlus = () => {
        if (qty < stock) {
            const items = JSON.parse(window.localStorage.getItem('cart'));
            items.forEach((item) => {
                if (item.id === itemId && item.format === format) {
                    item.qty += 1;
                }
            });

            window.localStorage.setItem('cart', JSON.stringify(items));
            setItems(items);
            setStockWarning(''); // Clear warning if the new quantity is valid
        } else {
            setStockWarning('Quantité maximale atteinte.');
            setTimeout(() => {
                setStockWarning('');
            }, 5000);
        }
    };

    const handleClickRemoveItem = () => {
        const items = JSON.parse(window.localStorage.getItem('cart'));
        items.forEach((item) => {
            if (item.id === itemId && item.format === format) {
                items.splice(items.indexOf(item), 1);
            }
        });

        window.localStorage.setItem('cart', JSON.stringify(items));
        setItems(items);
    };

    const price = (product.price_ttc * qty).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });

    return (
        <article className="cart_item">
            <hr className={isModalCart ? '' : 'hr-cart'} />
            <div key={'div-detail' + product.id} className="cart-item-detail">
                <Image id={product.id} path={product.pictures[0].url} isFigcaption={false} title={product.name} />
                <div key={'div' + product.id}>
                    <h1>{product.name}</h1>
                    <p>{format}</p>
                    {format !== 'Original' ? (
                        <div key={'qty' + product.id} className="cart-item-quantity">
                            <button onClick={handleClickMinus}>-</button>
                            <span>{qty}</span>
                            <button onClick={handleClickPlus}>+</button>
                            {stockWarning && <p className="cart-stock-warning">{stockWarning}</p>}
                        </div>
                    ) : null}
                </div>
                <span className="cart-item-price">{price}</span>
                <span className="remove-article-cart" onClick={handleClickRemoveItem}>
                    &times;
                </span>
            </div>
        </article>
    );
}
