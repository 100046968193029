import React, { useState } from 'react'
// Suppression de l'importation d'axios
// import axios from 'axios'

import Form from '../HTMLComponents/Form'

import '../../css/components/HeaderComponents/FormContactModal.css'

/**
 * Component which display the contact form inside the contact modal
 * @param {object} form 
 * @param {string} form.setSuccess - Setter of the success state from the contact modal
 * @returns {JSX.Element} Render the contact form component
 */
export const ContactFormHeader = ({ setSuccess }) => {

    // État pour les données du formulaire
    const [emailData, setEmailData] = useState({
        name: "",
        subject: "",
        email: "",
        msg: ""
    });

    // État pour la gestion des erreurs
    const [isError, setIsError] = useState({
        isNameError: false,
        isSubjectError: false,
        isEmailError: false,
        isMsgError: false
    });

    // Gestion des changements dans les champs du formulaire
    const handleChange = (e) => {
        setEmailData((prev) => ({ ...prev, [e.target.name]: e.target.value === "" ? "" : e.target.value }))
    }

    // Configuration des champs du formulaire
    const listForm = [
        { id: 1, tag: 'input', value: emailData.name, type: 'text', name: 'name', placeholder: 'Votre nom*', onchange: handleChange, iserror: isError.isNameError },
        { id: 2, tag: 'input', value: emailData.subject, type: 'text', name: 'subject', placeholder: 'Sujet du message*', onchange: handleChange, iserror: isError.isSubjectError },
        { id: 3, tag: 'input', value: emailData.email, type: 'text', name: 'email', placeholder: 'Votre adresse mail*', onchange: handleChange, iserror: isError.isEmailError },
        { id: 4, tag: 'textarea', value: emailData.msg, name: 'msg', placeholder: 'Votre message*', onchange: handleChange, iserror: isError.isMsgError },
    ]

    // Fonction de validation du formulaire
    const validateForm = () => {
        let errors = 0;

        if (!emailData.name) {
            setIsError((prev) => ({ ...prev, isNameError: true }))
            errors++
        } else
            setIsError((prev) => ({ ...prev, isNameError: false }))

        if (!emailData.subject) {
            setIsError((prev) => ({ ...prev, isSubjectError: true }))
            errors++
        } else
            setIsError((prev) => ({ ...prev, isSubjectError: false }))

        if (!emailData.email) {
            setIsError((prev) => ({ ...prev, isEmailError: true }))
            errors++
        } else if (!validateEmail(emailData.email)) {
            setIsError((prev) => ({ ...prev, isEmailError: true }))
            errors++
        } else
            setIsError((prev) => ({ ...prev, isEmailError: false }))

        if (!emailData.msg) {
            setIsError((prev) => ({ ...prev, isMsgError: true }))
            errors++
        } else
            setIsError((prev) => ({ ...prev, isMsgError: false }))

        if (errors !== 0)
            setSuccess("");

        return errors === 0;
    };

    // Fonction de validation de l'email
    const validateEmail = (email) => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
    };

    // Fonction d'envoi de l'email avec fetch
    const sendEmail = async (e) => {
        e.preventDefault()
        if (!validateForm()) {
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/send_mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailData)
            });

            if (!response.ok) {
                // Si la réponse n'est pas OK (statut HTTP non 2xx), on lance une erreur
                const errorData = await response.json();
                throw new Error(errorData.error || 'Erreur inconnue lors de l\'envoi de l\'email');
            }

            const data = await response.json();
            setSuccess('Le mail a été correctement envoyé !');
            console.log(data.message);
        } catch (error) {
            console.error('Le mail n\'a pas été envoyé correctement : ', error);
            // Vous pouvez également mettre à jour l'état pour afficher un message d'erreur à l'utilisateur
            setSuccess('Erreur lors de l\'envoi du mail. Veuillez réessayer plus tard.');
        }
    };

    return (
        <Form
            className="contactForm"
            listForm={listForm}
            onclick={sendEmail}
        />
    )
}
