import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/pages/admin/Shipments.css';

const Shipments = () => {
    const [shipments, setShipments] = useState([]);
    const [name, setName] = useState('');
    const [priceHT, setPriceHT] = useState('');
    const [priceTTC, setPriceTTC] = useState('');
    const [priority, setPriority] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchShipments = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/shipments`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des modes de paiements');
                }
                const data = await response.json();
                setShipments(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchShipments();
    }, []);

    const handleAddShipment = async (newShipment) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/shipments/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newShipment),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout du nouveau type d\'envoi');
            }

            const createdShipmentId = (await response.json()).shipmentId;
            const createdShipment = { id: createdShipmentId, ...newShipment };
            setShipments([...shipments, createdShipment]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateShipment = async (index, updatedShipment) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const shipmentId = shipments[index].id;
            const response = await fetch(`${apiUrl}/shipments/${shipmentId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedShipment),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour du type d\'envoi');
            }

            const updatedShipments = [...shipments];
            updatedShipments[index] = { ...shipments[index], ...updatedShipment };
            setShipments(updatedShipments);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteShipment = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const shipmentId = shipments[index].id;
            const response = await fetch(`${apiUrl}/shipments/${shipmentId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'ancien type d\'envoi');
            }

            const updatedShipments = shipments.filter((_, i) => i !== index);
            setShipments(updatedShipments);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newShipment = {
            name,
            price_ht: parseFloat(priceHT),
            price_ttc: parseFloat(priceTTC),
            priority
        };

        if (editIndex !== null) {
            await handleUpdateShipment(editIndex, newShipment);
        } else {
            await handleAddShipment(newShipment);
        }

        setName('');
        setPriceHT('');
        setPriceTTC('');
        setPriority('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        const shipment = shipments[index];
        setName(shipment.name || '');
        setPriceHT(shipment.price_ht || '');
        setPriceTTC(shipment.price_ttc || '');
        setPriority(shipment.priority || '');
        setEditIndex(index);
    };

    if (loading) {
        return <div>Chargement des shipments...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_shipments">
            <div>
                <h1>Gestion des types d'envoi</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom du type d'envoi</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="priceHT">Prix HT</label>
                        <input
                            type="number"
                            id="priceHT"
                            value={priceHT || ''}
                            onChange={(e) => setPriceHT(e.target.value)}
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="priceTTC">Prix TTC</label>
                        <input
                            type="number"
                            id="priceTTC"
                            value={priceTTC || ''}
                            onChange={(e) => setPriceTTC(e.target.value)}
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="priority">Priorité d'envoi</label>
                        <input
                            type="number"
                            id="priority"
                            value={priority || ''}
                            onChange={(e) => setPriority(e.target.value)}
                            step="1"
                            required
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des types d'envoi</h2>
                <ul>
                    {shipments.map((shipment, index) => (
                        <li key={shipment.id}>
                            <div
                                className="adminShipmentDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <span>{shipment.name} - {shipment.price_ttc}€ / ({shipment.priority})</span>
                            </div>
                            <button onClick={() => handleDeleteShipment(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Shipments;