import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Component which render a svg icon
 * @param {object} svg
 * @param {string} svg.className
 * @param {string} svg.alt
 * @param {string} svg.title
 * @param {boolean} svg.isRedirectExt - If true, the svg redirect outside of the website
 * @param {boolean} svg.isRedirectRoute - If true, the svg redirect to another page
 * @param {string} svg.href - The path of the redirection
 * @param {string} svg.onclick - If not null, execute the onclick function
 * @returns {JSX.Element} Return the svg component
 */
export default function Svg({className, alt, title, isRedirectExt, isRedirectRoute, href, onClick}) {
  
    const svg = []

    if (isRedirectExt) {
        svg.push(
            <a key={"a"+className} href={href} target="_blank" rel="noreferrer">
                <svg key={className} className={className} alt={alt} title={title} />
            </a>
        )
    } else if (isRedirectRoute) {
        svg.push(
            <Link key={'Link'+className} to={href}>
                <svg key={className} className={className} alt={alt} title={title} />
            </Link>
        )
    } else {
        svg.push(<svg key={className} className={className} alt={alt} title={title} onClick={onClick !== null ? onClick : null }/>)
    }
  
    return (<>
        {svg}
    </>)
}
