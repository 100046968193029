import React from 'react'

/**
 * Component which render an image with/without a caption
 * @param {Object} image
 * @param {number} image.id - ID of the image
 * @param {string} image.path - Path of the image
 * @param {string} image.alt - Alternate text if the image doesn't display
 * @param {string} image.title - Title of the image and of its caption
 * @param {string} image.classname - CSS class of the image
 * @param {boolean} image.isFigcaption - Set to 'true' if a caption is needed
 * @returns {JSX.Element} Render the image component
 */
export default function Image({id, path, alt, title, classname, isFigcaption}) {

  const image = []

    image.push(<img key={"img-"+id} src={path} alt={alt} title={title} />)

    if(isFigcaption)
      image.push(<figcaption key={"figcaption-"+id}>{title}</figcaption>)

  return (
    <figure className={classname}>
        {image}
    </figure>
  )
}
