import React from 'react';
import { Link } from 'react-router-dom';
import ImageOverlay from './ImageOverlay';

import '../../css/components/ShopComponents/ShopImage.css';

export const ShopImage = ({ id, path, alt, title, price, handleModal, product }) => {

    return (
        <article className={'shop-image'}>
            <Link to={`/produit/${id}`}>
                <img src={path} alt={alt} title={title}></img>
            </Link>
            <ImageOverlay idProduct={id} handleModal={handleModal} product={product} />
            <p>
                <span>{title}</span><br />{price}
            </p>
        </article>
    );
};
