import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/pages/admin/Payments.css';

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [name, setName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/payments`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des modes de paiements');
                }
                const data = await response.json();
                setPayments(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchPayments();
    }, []);

    const handleAddPayment = async (newPayment) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/payments/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPayment),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout du nouveau mode de paiement');
            }

            const createdPaymentId = (await response.json()).paymentId;
            const createdPayment = { id: createdPaymentId, ...newPayment };
            setPayments([...payments, createdPayment]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdatePayment = async (index, updatedPayment) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const paymentId = payments[index].id;
            const response = await fetch(`${apiUrl}/payments/${paymentId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedPayment),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour du mode de paiement');
            }

            const updatedPayments = [...payments];
            updatedPayments[index] = { ...payments[index], ...updatedPayment };
            setPayments(updatedPayments);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeletePayment = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const paymentId = payments[index].id;
            const response = await fetch(`${apiUrl}/payments/${paymentId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'ancien mode de paiement');
            }

            const updatedPayments = payments.filter((_, i) => i !== index);
            setPayments(updatedPayments);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newPayment = {
            name,
        };

        if (editIndex !== null) {
            await handleUpdatePayment(editIndex, newPayment);
        } else {
            await handleAddPayment(newPayment);
        }

        setName('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        const payment = payments[index];
        setName(payment.name || '');
        setEditIndex(index);
    };

    if (loading) {
        return <div>Chargement des moyens de paiements...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_payments">
            <div>
                <h1>Gestion des moyens de paiements</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom du moyen de paiement</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des moyens de paiement</h2>
                <ul>
                    {payments.map((payment, index) => (
                        <li key={payment.id}>
                            <div
                                className="adminPaymentDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <span>{payment.name}</span>
                            </div>
                            <button onClick={() => handleDeletePayment(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Payments;