import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import Paragraph from '../components/HTMLComponents/Paragraph';
import Image from '../components/HTMLComponents/Image';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';

import '../css/pages/Activities.css';

const Activities = () => {
    const [activities, setActivities] = useState([]); // Liste des activités
    const [loading, setLoading] = useState(true); // État de chargement
    const [error, setError] = useState(null); // Gestion des erreurs

    let classname = 'activities';

    // Modifier la classe CSS si le chemin correspond
    if (window.location.pathname === '/activites') classname += ' fixed_main';

    useEffect(() => {
        const fetchActivitiesAndPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;

                // Récupération de toutes les activités avec leurs images
                const response = await fetch(`${apiUrl}/activities/pictures`);
                if (!response.ok) throw new Error('Erreur lors du chargement des activités');
                const activitiesData = await response.json();

                setActivities(activitiesData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivitiesAndPictures();
    }, []);

    if (loading) return <div>Chargement des activités...</div>;
    if (error) return <div>Erreur : {error}</div>;

    if (activities.length === 0) return <div>Aucune activité disponible.</div>;

    return (
        <>
            <Header />
            <main className={classname}>
                {activities.map((activity) => (
                    <div key={activity.id} className="activity_section">
                        <section className="activities_header">
                            <h1>{activity.title}</h1>
                            <span>{activity.year}</span>
                        </section>
                        <Paragraph content={[{ id: 1, text: activity.description }]} />
                        <section className="activities_grid">
                            {activity.pictures.map((picture) => (
                                <Image
                                    key={picture.id} // Identifiant unique pour React
                                    path={picture.url} // URL complète de l'image
                                    isFigcaption={!!picture.figcaption} // Si une légende existe
                                    title={picture.figcaption || ''} // Si une légende existe, sinon vide
                                />
                            ))}
                        </section>
                    </div>
                ))}
            </main>
            <ShopHeader />
        </>
    );
};

export default Activities;
