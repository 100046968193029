import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import Image from '../components/HTMLComponents/Image';

import '../css/pages/Home.css';

/**
 * Component which displays the homepage of the website
 * @returns {JSX.Element}
 */
const Home = () => {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHomepagePictures = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/pictures/homepage`); // Route pour les images avec isHomepage=true
        if (!response.ok) throw new Error('Erreur lors du chargement des images en page d\'accueil');
        const data = await response.json();
        setPictures(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchHomepagePictures();
  }, []);

  if (loading) return <p>Chargement des images...</p>;
  if (error) return <p>Erreur : {error}</p>;

  // Combler avec des placeholders si moins de 7 images
  const filledPictures = [
    ...pictures,
    ...Array.from({ length: Math.max(0, 7 - pictures.length) }).map(() => ({
      url: "https://picsum.photos/404/290",
      altText: "Lorem Ipsum",
      title: "Photo aléatoire",
    })),
  ];

  return (
    <>
      <Header />
      <main className="home_grid">
        {filledPictures.map((picture, index) => (
          <Image
            key={index}
            classname={
              index === 0 ? "grid_landscape" :
              index === 2 ? "grid_portrait" :
              undefined
            }
            path={picture.url}
            alt={picture.altText}
            title={picture.title}
            isFigcaption={true}
          />
        ))}
      </main>
    </>
  );
};

export default Home;
