import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/pages/admin/Collections.css';

const Collections = () => {
    const [collections, setCollections] = useState([]);
    const [name, setName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/collections`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des collections');
                }
                const data = await response.json();
                setCollections(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchCollections();
    }, []);

    const handleAddCollection = async (newCollection) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/collections/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCollection),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout de la collection');
            }

            const createdCollectionId = (await response.json()).collectionId;
            const createdCollection = { id: createdCollectionId, ...newCollection };
            setCollections([...collections, createdCollection]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateCollection = async (index, updatedCollection) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const collectionId = collections[index].id;
            const response = await fetch(`${apiUrl}/collections/${collectionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedCollection),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour de la collection');
            }

            const updatedCollections = [...collections];
            updatedCollections[index] = { ...collections[index], ...updatedCollection };
            setCollections(updatedCollections);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteCollection = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const collectionId = collections[index].id;
            const response = await fetch(`${apiUrl}/collections/${collectionId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de la collection');
            }

            const updatedCollections = collections.filter((_, i) => i !== index);
            setCollections(updatedCollections);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newCollection = {
            name,
        };

        if (editIndex !== null) {
            await handleUpdateCollection(editIndex, newCollection);
        } else {
            await handleAddCollection(newCollection);
        }

        setName('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        const collection = collections[index];
        setName(collection.name || '');
        setEditIndex(index);
    };

    if (loading) {
        return <div>Chargement des collections...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_collections">
            <div>
                <h1>Gestion des collections</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom de la collection</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des collections</h2>
                <ul>
                    {collections.map((collection, index) => (
                        <li key={collection.id}>
                            <div
                                className="adminCollectionDiv"
                                onClick={() => handleEdit(index)}
                            >
                                <span>{collection.name}</span>
                            </div>
                            <button onClick={() => handleDeleteCollection(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Collections;
