import React from 'react'

/**
 * Component which render a HTML input
 * @param {object} input
 * @param {string} input.type
 * @param {boolean} input.iserror - If there is an error, change the CSS class of the input
 * @param {string} input.name
 * @param {string} input.placeholder
 * @param {string} input.value
 * @param {string} input.onchange
 * @returns {JSX.Element} Render the input component
 */
export default function Input({type, iserror, name, placeholder, value, onchange}) {
  return (
    <input type={type} className={iserror ? "error_alert" : ""} name={name} placeholder={placeholder} value={value} onChange={onchange} />
  )
}
