import React from 'react'

/**
 * Component which render a HTML paragraph
 * @param {object} paragraph
 * @param {object[]} paragraph.content - An array of objects which contains all the lines
 * @param {number} paragraph.content[].id
 * @param {string} paragraph.content[].text
 * @returns {JSX.Element} Render the paragraph component
 */
export default function Paragraph({content}) {

    const p = []

    for (const line of content) {
        p.push(<React.Fragment key={line.id}>{line.text}<br/></React.Fragment>)
    }

    return (
        <p>
            {p}
        </p>
    )
}
