import React from 'react'
import { Link } from "react-router-dom"

import Image from '../HTMLComponents/Image'

import '../../css/components/HeaderComponents/LogoHeader.css'
import LaMalletteLogo from '../../assets/logo-la-mallette.png'

/**
 * Component which render the logo of the website. Allow users to navigate to the homepage
 * @returns {JSX.Element} Render the logo component
 */
export const LogoHeader = () => {
  return (
    <section className="logoHeader">
      <Link to={"/"}>
          <Image
            path={LaMalletteLogo}
            alt="Logo Atelier La Mallette"
            title="Logo - Atelier La Mallette"
            isFigcaption={false}
          />
      </Link>
      </section>
  )
}
