import React from 'react'
import Paragraph from './HTMLComponents/Paragraph'
import { Link } from 'react-router-dom'

import '../css/components/Footer.css'

/**
 * Component which display the footer of the website
 * @returns {JSX.Element}
 */
export const Footer = () => {

  // Array of objects which contains all the data needed for the paragraph
  const content = [
    {id: 1, text: 'Tout droits réservés'},
    {id: 2, text: <><Link to="/credits">Crédits / Mentions Légales</Link></>},
    {id: 3, text: 'Design : Lucie DECK'},
    {id: 4, text: 'Développement : Jean DECK'}
  ]

  return (
    <footer>
      <Paragraph content={content} />
    </footer>
  )
}
