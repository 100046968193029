import React, { useState } from 'react'

import Svg from '../HTMLComponents/Svg'
import { ContactModalHeader } from './ContactModalHeader';
import '../../css/components/HeaderComponents/SocialsHeader.css'

/**
 * Component which display all the socials medias of the owner of the website
 * @returns {JSX.Element} Return the social media component
 */
export const SocialsHeader = () => {
    // If true, the modal is on screen
    const [isModalDisplay, setModalDisplay] = useState(false);

    // Change the state of the modal
    const displayModal = () => {
        setModalDisplay(prev => !prev);
    }
  
    return (
        <section className="socialsHeader">
            <Svg
                className="icon_instagram"
                alt=""
                title='Visiter "La Mallette" sur Instagram'
                isRedirectExt={true}
                isRedirectRoute={false}
                href="https://www.instagram.com/atelier.lamallette/"
            />
            <Svg
                className="icon_mail"
                alt=""
                title='Contacter "La Mallette"'
                isRedirectExt={false}
                isRedirectRoute={false}
                onClick={displayModal}
            />
            {isModalDisplay &&
                <ContactModalHeader onClickParent={displayModal} />
            }
        </section>
    )
}
