import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PicturesModal from '../../components/AdminComponents/PicturesModal';

import '../../css/pages/admin/Activities.css';

const Activities = () => {

    const [activities, setActivities] = useState([]);
    const [title, setTitle] = useState('');
    const [year, setYear] = useState('');
    const [description, setDescription] = useState('');
    const [pictures, setPictures] = useState([]);
    const [allPictures, setAllPictures] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/activities/pictures`); // On récupère les activités avec leurs images
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des activités');
                }
                const data = await response.json();
                setActivities(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchActivities();
    }, []);

    useEffect(() => {
        // Charger toutes les images disponibles
        const fetchPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/pictures`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des images');
                }
                const data = await response.json();
                setAllPictures(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchPictures();
    }, []);

    const handleAddActivity = async (newActivity) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/activities/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newActivity),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'ajout de l\'activité');
            }

            const createdActivity = await response.json();
            setActivities([...activities, { id: createdActivity.activityId, ...newActivity }]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateActivity = async (index, updatedActivity) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const activityId = activities[index].id;
            const response = await fetch(`${apiUrl}/activities/${activityId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedActivity),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de l\'activité');
            }

            const updatedActivities = [...activities];
            updatedActivities[index] = { ...activities[index], ...updatedActivity };
            setActivities(updatedActivities);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteActivity = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const activityId = activities[index].id;
            const response = await fetch(`${apiUrl}/activities/${activityId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression de l\'activité');
            }

            const updatedActivities = activities.filter((_, i) => i !== index);
            setActivities(updatedActivities);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshActivities = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/activities/pictures`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des activités');
            const data = await response.json();
            setActivities(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des activités :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newActivity = { title, year, description, pictures: pictures.map(p => p.id) }; // Envoyer seulement les IDs des images

        if (editIndex !== null) {
            // Mise à jour de l'activité
            await handleUpdateActivity(editIndex, newActivity);
        } else {
            // Ajout d'une nouvelle activité
            await handleAddActivity(newActivity);
        }

        await refreshActivities();

        // Réinitialiser les champs après ajout ou mise à jour
        setTitle('');
        setYear('');
        setDescription('');
        setPictures([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setEditIndex(null);
            setTitle('');
            setYear('');
            setDescription('');
            setPictures([]);
        } else {
            const activity = activities[index];
            setTitle(activity.title || '');
            setYear(activity.year || '');
            setDescription(activity.description || '');
            setPictures(activity.pictures || []);
            setEditIndex(index);
        }
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePicturesChange = (selectedPictures) => {
        setPictures(selectedPictures);
    };

    if (loading) {
        return <div>Chargement des activités...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_activities">
            <div>
                <h1>Gestion des activités</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="title">Titre</label>
                        <input
                            type="text"
                            id="title"
                            value={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="year">Année</label>
                        <input
                            type="number"
                            id="year"
                            value={year || ''}
                            onChange={(e) => setYear(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <button type="button" onClick={openModal}>Gérer les images</button>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des activités</h2>
                <ul>
                    {activities.map((activity, index) => (
                        <li key={`${activity.id}-${index}`}>
                            <div
                                className="adminActivityDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <h3>{activity.title} - {activity.year}</h3>
                                <p>{activity.description}</p>
                            </div>
                            <button onClick={() => handleDeleteActivity(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>

            {showModal && (
                <PicturesModal
                    associatedPictures={pictures}
                    allPictures={allPictures}
                    onClose={closeModal}
                    onConfirm={handlePicturesChange}
                />
            )}
        </section>
    );
};

export default Activities;
