import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import '../../css/pages/admin/Keywords.css';

const Keywords = () => {

    const [keywords, setKeywords] = useState([]);
    const [name, setName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchKeywords = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/keywords`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des mots-clés');
                }
                const data = await response.json();
                setKeywords(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchKeywords();
    }, []);

    const handleAddKeyword = async (newKeyword) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/keywords/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newKeyword),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout du mot-clé');
            }

            const createdKeyword = await response.json();
            setKeywords([...keywords, createdKeyword]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateKeyword = async (index, updatedKeyword) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const keywordId = keywords[index].id;
            const response = await fetch(`${apiUrl}/keywords/${keywordId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedKeyword),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour du mot-clé');
            }

            const updatedKeywords = [...keywords];
            updatedKeywords[index] = { ...keywords[index], ...updatedKeyword };
            setKeywords(updatedKeywords);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteKeyword = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const keywordId = keywords[index].id;
            const response = await fetch(`${apiUrl}/keywords/${keywordId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la suppression de l\'utilisateur');
            }
    
            const updatedKeywords = keywords.filter((_, i) => i !== index);
            setKeywords(updatedKeywords);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshKeywords = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/keywords`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des mots-clés');
            const data = await response.json();
            setKeywords(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des mots-clés :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newKeyword = { name };

        if (editIndex !== null) {
            // Mise à jour de l'utilisateur
            await handleUpdateKeyword(editIndex, newKeyword);
        } else {
            // Ajout d'un nouvel utilisateur
            await handleAddKeyword(newKeyword);
        }

        await refreshKeywords();

        // Réinitialiser les champs après ajout ou mise à jour
        setName('');
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setEditIndex(null);
            setName('');
        } else {
            const keyword = keywords[index];
            setName(keyword.name || '');
            setEditIndex(index);
        }
    };

    if (loading) {
        return <div>Chargement des mots-clés...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="keywords">
            <div>
                <h1>Gestion des mots-clés</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''} // Valeur par défaut
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des mots-clés</h2>
                <ul>
                    {keywords.map((keyword, index) => (
                        <li key={`${keyword.id}-${index}`}>
                            <div
                                className="keywordDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <span>{keyword.name}</span>
                            </div>
                            <button onClick={() => handleDeleteKeyword(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Keywords;