import React from 'react'

/**
 * Component which render a HTML textarea
 * @param {object} textarea
 * @param {boolean} textarea.iserror - If true, change the CSS class for displaying an error
 * @param {string} textarea.name
 * @param {string} textarea.placeholder
 * @param {string} textarea.value
 * @param {string} textarea.onchange
 * @returns {JSX.Element} Render the textarea component
 */
export default function Textarea({iserror, name, placeholder, value, onchange}) {
  return (
    <textarea className={iserror ? "error_alert" : ""} name={name} placeholder={placeholder} value={value} onChange={onchange} ></textarea>
  )
}
