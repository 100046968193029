import React, { useEffect, useState } from 'react'
import Svg from '../HTMLComponents/Svg'

import "../../css/components/HeaderComponents/ShopHeader.css"

/**
 * Component which display the cart icon. Allow the users to see what they want to buy
 * @returns {JSX.Element} Render the shop component
 */
export const ShopHeader = () => {

  const [products, setProducts] = useState(0)

  let classname = 'shopHeader'
  const items = JSON.parse(window.localStorage.getItem('cart'))

  if(window.location.pathname === '/projets' || window.location.pathname === '/activites' || window.location.pathname === '/boutique')
    classname += ' fixed'

  useEffect(() => {
    if(items !== null)
      setProducts(items.length)
  }, [items])

  return (
    <section className={classname} >
        <Svg
            className="icon_cart"
            alt=""
            title="Accéder à votre panier"
            isRedirectExt={false}
            isRedirectRoute={true}
            href="/panier"
        />
        {products > 0 ? <span className="shopHeaderProducts">{products}</span> : null}
    </section>
  )

}
