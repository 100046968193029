import React, { useCallback, useEffect, useRef, useState } from 'react'

import Paragraph from '../HTMLComponents/Paragraph'
import { ContactFormHeader } from './ContactFormHeader'

import '../../css/components/HeaderComponents/ContactModalHeader.css'

/**
 * Component which display the contact modal if the user clicked on the mail icon
 * @param {object} modal
 * @param {string} modal.onClickParent - The function which allow users to display the modal
 * @returns {JSX.Element} Render the contact modal component
 */
export const ContactModalHeader = ({onClickParent}) => {

    let classname = 'modal'

    if(window.location.pathname === '/projets' || window.location.pathname === '/activites' || window.location.pathname === '/boutique' || window.location.pathname === '/infos')
        classname += ' fixed_modal'

    // Array of objects which contains all the data needed for the paragraph component
    const content = [
        {id: 1, text: 'Lucie DECK'},
        {id: 2, text: 'Atelier la Mallette'},
        {id: 3, text: '06.69.11.06.79'}
    ]

    // Boolean state which tell if the modal is correctly display on screen before trying to remove it
    const [isModalDisplay, setIsModalDisplay] = useState(false)
    // Ref of the entire component. Need it for understand where the user click on screen
    const sectionRef = useRef(null);
    // String which contains the message of a mail sended with success
    const [success, setSuccess] = useState("");

    /**
     * Callback which close the modal if users click outside the modal
     */
    const handleOnClick = useCallback((event = MouseEvent) => {
        setTimeout(() => {
            setIsModalDisplay(true)
        }, 100)
        if(sectionRef.current && !event.composedPath().includes(sectionRef.current) && isModalDisplay) {
            onClickParent()
        }
    }, [isModalDisplay, onClickParent])

    /**
     * Callback which close the modal if users press the "Escape" key
     */
    const handleOnKeyDown = useCallback((event = KeyboardEvent) => {
        setTimeout(() => {
            setIsModalDisplay(true)
        }, 100)
        if((event.keyCode === 27 && isModalDisplay) || (event.code === 'Escape' && isModalDisplay))
        {
            onClickParent()
        }
    }, [isModalDisplay, onClickParent])

    // Add two events listener when the modal appears on screen. Remove them when users close the modal.
    useEffect(() => {
        document.body.addEventListener('click', handleOnClick)
        document.body.addEventListener('keydown', handleOnKeyDown)
        return () => {
            document.body.removeEventListener('click', handleOnClick)
            document.body.removeEventListener('keydown', handleOnKeyDown)
        }
    }, [sectionRef, onClickParent, handleOnClick, handleOnKeyDown])

    // Display a success message if the mail was sended correctly
    function AlertSuccess({success}) {
        if(success !== "") {
          return <h3>{success}</h3>
        }
    }

    return (
        <section ref={sectionRef} className={classname}>
            <div>
                <article className="contactInfos">
                    <Paragraph content={content} />
                </article>
                <article className="alertContact">
                    <AlertSuccess className="alertSuccess" success={success} />
                </article>
            </div>
            <ContactFormHeader setSuccess={setSuccess} />
            <span className="closeButtonContact" onClick={onClickParent}>&times;</span>
        </section>
    )
}
