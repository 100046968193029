import React, { useState, useEffect } from 'react';
import '../../css/components/AdminComponents/FormatsModal.css';

const FormatsModal = ({ associatedFormats, allFormats, onClose, onConfirm }) => {
    const [leftFormats, setLeftFormats] = useState([]);
    const [rightFormats, setRightFormats] = useState([]);
    const [isLeftDragOver, setIsLeftDragOver] = useState(false);
    const [isRightDragOver, setIsRightDragOver] = useState(false);

    useEffect(() => {
        setLeftFormats(associatedFormats || []);
        const associatedIds = (associatedFormats || []).map(f => f.id);
        const unassociatedFormats = allFormats.filter(f => !associatedIds.includes(f.id));
        setRightFormats(unassociatedFormats);
    }, [associatedFormats, allFormats]);

    const handleDragStart = (e, format, fromLeft) => {
        e.dataTransfer.setData('format', JSON.stringify(format));
        e.dataTransfer.setData('fromLeft', fromLeft);
    };

    const handleDrop = (e, targetLeft) => {
        e.preventDefault();
        const format = JSON.parse(e.dataTransfer.getData('format'));
        const fromLeft = e.dataTransfer.getData('fromLeft') === 'true';

        if (fromLeft && !targetLeft) {
            setLeftFormats(leftFormats.filter(f => f.id !== format.id));
            setRightFormats([...rightFormats, format]);
        } else if (!fromLeft && targetLeft) {
            setRightFormats(rightFormats.filter(f => f.id !== format.id));
            setLeftFormats([...leftFormats, { ...format, stock: 0 }]); // Ajoutez un champ stock par défaut
        }

        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(true);
        } else {
            setIsRightDragOver(true);
        }
    };

    const handleDragLeave = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const handleStockChange = (formatId, value) => {
        setLeftFormats(leftFormats.map(f => f.id === formatId ? { ...f, stock: value } : f));
    };

    const handleConfirm = () => {
        onConfirm(leftFormats);
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les formats</h2>
                <div className="modal-body">
                    <div
                        className={`format-list left ${isLeftDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, true)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, true)}
                        onDragLeave={(e) => handleDragLeave(e, true)}
                    >
                        <h3>Formats associés</h3>
                        <div className="formats-container">
                            {leftFormats.map((format) => (
                                <div
                                    key={format.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, format, true)}
                                    className="format-item"
                                >
                                    <span>{format.name}</span>
                                    <input
                                        type="number"
                                        value={format.stock || 0}
                                        onChange={(e) => handleStockChange(format.id, parseInt(e.target.value))}
                                        placeholder="Stock"
                                        min="0"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`format-list right ${isRightDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, false)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, false)}
                        onDragLeave={(e) => handleDragLeave(e, false)}
                    >
                        <h3>Formats disponibles</h3>
                        <div className="formats-container">
                            {rightFormats.map((format) => (
                                <div
                                    key={format.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, format, false)}
                                    className="format-item"
                                >
                                    <span>{format.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default FormatsModal;
