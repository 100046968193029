import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const RecycleBin = () => {
    const [datas, setDatas] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fonction pour récupérer les utilisateurs depuis une API
    useEffect(() => {
        const fetchDatas = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/restore`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des données');
                }
                const data = await response.json();
                setDatas(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchDatas();
    }, []);

    const handleRestore = async (index, id, table_name) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/restore/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ table_name }), // Envoie create_date et table_name
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la restauration de la donnée');
            }

            // Mise à jour des données après suppression de l'élément restauré
            const updatedDatas = datas.filter((_, i) => i !== index);
            setDatas(updatedDatas);
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div>Chargement des données...</div>;
    if (error) return <div>Erreur : {error}</div>;

    return (
        <section className="recyclebin">
            <h1>Corbeille</h1>
            <table>
                <thead>
                    <tr>
                        <th>Nom de la table</th>
                        <th>Nom de la donnée</th>
                        <th>Date de création</th>
                        <th>Date de suppression</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {datas.map((data, index) => (
                        <tr key={`${data.id}-${index}`}>
                            <td>{data.table_name}</td>
                            <td>{data.name}</td>
                            <td>{new Date(data.create_date).toLocaleString('fr-FR')}</td>
                            <td>{new Date(data.delete_date).toLocaleString('fr-FR')}</td>
                            <td>
                                <button
                                    onClick={() => handleRestore(index, data.id, data.table_name)}
                                >
                                    Restaurer
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
        </section>
    );
};

export default RecycleBin;
