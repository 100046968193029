import React from 'react'
import Header from '../components/Header'

import PDF_CGV from '../assets/lamallette_CGV.pdf'

import '../css/pages/Credits.css'

/**
 * Credits page component
 * @returns {JSX.Element}
 */
export const Credits = () => {
    return (
        <>
            <Header />
            <main className='credits'>
                <section className='credits-section'>
                    <div className="credits-section-left">
                        <article className='credits-section-editor'>
                            <h1>Éditeur</h1>
                            <p>
                                Atelier la mallette Lucie DECK<br/>
                                atelierlamallette@gmail.com<br/>
                                8 rue de l'église 21400 NOD-SUR-SEINE<br/>
                                SIRET 849 653 985 00010<br/>
                                CODE APE 74 10 Z<br/>
                                T. 06 69 11 06 79<br/>
                            </p>
                        </article>
                        <article className='credits-section-dev'>
                            <h1>Développeur</h1>
                            <p>
                                Jean DECK - Développeur FullStack<br/>
                                jdeck.dw@gmail.com<br/>
                                www.jeandeck.fr<br/>
                                T. 06 67 15 52 88<br/>
                            </p>
                        </article>
                        <article className='credits-section-hosting'>
                            <h1>Hébergement</h1>
                            <p>
                                Bidule<br/>
                                XXXXXXXXXXXXX<br/>
                                XXXXXXXXXXX<br/>
                            </p>
                        </article>
                        <article className='credits-section-property1'>
                            <h1>Propriétés</h1>
                            <p>
                                L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Toute réprésentation, reproduction intégrale ou partielle, modification, utilisation commerciale, ainsi que tout transfert vers un autre site ou tout autre support numérique ou imprimé sont strictement interdits, sauf autorisations suite à une demande formulée par courriel à l'éditeur.
                            </p>
                        </article>
                    </div>
                    <div className="credits-section-right">
                        <article className='credits-section-property2'>
                            <p>
                                Toute utilisation frauduleuse de tout ou une partie du site pourra donner lieu à des poursuites. Les droits de propriété intellectuelle relatifs aux contenus de ce site sont la propriété intellectuelle sur le code source des pages de ce site et sur les éléments graphiques sont la propriété respective et exclusive de Jean DECK et de Atelier la mallette.
                            </p>
                        </article>
                        <article className='credits-section-credits'>
                            <h1>Crédits</h1>
                            <span>Illustrations/Graphisme/Photographies :</span>
                            <p>
                                Atelier la mallette - Lucie DECK
                            </p>
                            <span>Développement web :</span>
                            <p>
                                Jean DECK
                            </p>
                        </article>
                        <article className='credits-section-cgv'>
                            <h1>Conditions générales de vente</h1>
                            <a href={PDF_CGV} target="_blanck">PDF téléchargeable</a>
                        </article>
                    </div>
                </section>
            </main>
        </>
    )
}
