import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Image from '../../components/HTMLComponents/Image';
import KeywordsModal from '../../components/AdminComponents/KeywordsModal';

import '../../css/pages/admin/Pictures.css';

const Pictures = () => {
    const [pictures, setPictures] = useState([]); // Liste des images
    const [title, setTitle] = useState(''); // Titre de l'image
    const [altText, setAltText] = useState(''); // Texte alternatif de l'image
    const [homepage, setHomepage] = useState(false); // Booléen pour la page d'accueil
    const [figcaption, setFigcaption] = useState(''); // Texte de la légende de l'image
    const [editIndex, setEditIndex] = useState(null); // Index de l'image à éditer
    const [previewImage, setPreviewImage] = useState(null); // Aperçu de l'image
    const [file, setFile] = useState(null); // Fichier sélectionné
    const [order, setOrder] = useState(0); // Ordre
    const [selectedKeywords, setSelectedKeywords] = useState([]); // Mots-clés associés
    const [showModal, setShowModal] = useState(false); // Gestion de la modale
    const [loading, setLoading] = useState(true); // Indicateur de chargement
    const [error, setError] = useState(null); // Gestion des erreurs

    useEffect(() => {
        const fetchPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/pictures`);
                if (!response.ok) throw new Error('Erreur lors du chargement des images');
                const data = await response.json();
                setPictures(data); // Mettre à jour la liste des images
            } catch (err) {
                setError(err.message); // Afficher l'erreur
            } finally {
                setLoading(false); // Indiquer que le chargement est terminé
            }
        };
        fetchPictures();
    }, []);

    const handleAddPicture = async (newPicture) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const formData = new FormData();
            formData.append('title', newPicture.title);
            formData.append('altText', newPicture.altText);
            formData.append('homepage', newPicture.homepage);
            formData.append('figcaption', newPicture.figcaption);
            formData.append('order', newPicture.order); // Ajout de l'ordre
            formData.append('file', newPicture.file);
            formData.append('keywords', JSON.stringify(newPicture.keywords));
    
            const response = await fetch(`${apiUrl}/pictures/add`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) throw new Error('Erreur lors de l\'ajout de l\'image');
    
            const createdPicture = await response.json();
            setPictures((prev) => [...prev, createdPicture]); // Ajouter l'image à la liste
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdatePicture = async (index, updatedPicture) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const pictureId = pictures[index].id;
    
            const response = await fetch(`${apiUrl}/pictures/${pictureId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedPicture),
            });
    
            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour de l'image");
            }
    
            const updatedPictureData = await response.json();
            setPictures((prev) =>
                prev.map((pic, i) => (i === index ? updatedPictureData : pic))
            );
            setEditIndex(null); // Quitter le mode édition
        } catch (err) {
            console.error('Erreur :', err);
            setError(err.message);
        }
    };

    const handleDeletePicture = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const pictureId = pictures[index].id;

            const response = await fetch(`${apiUrl}/pictures/${pictureId}/delete`, {
                method: 'PUT',
            });

            if (!response.ok) throw new Error('Erreur lors de la suppression de l\'image');

            setPictures((prev) => prev.filter((_, i) => i !== index)); // Supprimer localement
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshPictures = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/pictures`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des images');
            const data = await response.json();
            setPictures(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des images :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPicture = { title, altText, homepage, figcaption, file, order: homepage ? order : 0, keywords: selectedKeywords };
        editIndex !== null
            ? await handleUpdatePicture(editIndex, newPicture)
            : await handleAddPicture(newPicture);
        resetForm();
        await refreshPictures();
    };

    const handleEdit = (index, isHomepage) => {
        const { title, altText, figcaption, order, keywords } = pictures[index];
        setTitle(title || ''); // Fallback à une chaîne vide
        setAltText(altText || ''); // Fallback à une chaîne vide
        setHomepage(isHomepage ?? false); // Fallback à false
        setFigcaption(figcaption || ''); // Fallback à une chaîne vide
        setOrder(order || 0); // Fallback à 0
        setSelectedKeywords(keywords || []); // Fallback à un tableau vide
        setEditIndex(index); // Activer le mode édition
    };

    const resetForm = () => {
        setTitle('');
        setAltText('');
        setHomepage(false);
        setFigcaption('');
        setOrder(0); // Réinitialiser l'ordre
        setPreviewImage(null);
        setFile(null);
        setSelectedKeywords([]);
        setEditIndex(null);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner"></div>
                <span>Chargement des images...</span>
            </div>
        );
    }

    if (error) return <div>Erreur : {error}</div>;

    return (
        <section className="pictures">
            <div>
                <h1>Gestion des images</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="fileInput">Image :</label>
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (event) => setPreviewImage(event.target.result);
                                    reader.readAsDataURL(file);
                                    setFile(file);
                                    setTitle(file.name.replace(/\.[^/.]+$/, ''));
                                }
                            }}
                        />
                    </div>
                    {previewImage && <img src={previewImage} alt="Aperçu" style={{ maxWidth: '100px' }} />}
                    <div>
                        <label htmlFor="title">Titre</label>
                        <input
                            type="text"
                            id="title"
                            value={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="altText">Texte alternatif</label>
                        <input
                            type="text"
                            id="altText"
                            value={altText || ''}
                            onChange={(e) => setAltText(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={homepage}
                                onChange={(e) => setHomepage(e.target.checked)}
                            />
                            Page d'accueil
                        </label>
                    </div>
                    <div>
                        <label htmlFor="figcaption">Légende</label>
                        <input
                            type="text"
                            id="figcaption"
                            value={figcaption || ''}
                            onChange={(e) => setFigcaption(e.target.value)}
                        />
                    </div>
                    {homepage && (
                        <div>
                            <label htmlFor="order">Ordre</label>
                            <input
                                type="number"
                                id="order"
                                value={order || ''}
                                onChange={(e) => setOrder(Number(e.target.value))}
                                required
                            />
                        </div>
                    )}
                    <div>
                        <button type="button" onClick={() => setShowModal(true)}>
                            Ajouter des mots-clés
                        </button>
                        <ul>
                            {selectedKeywords.map((kw) => (
                                <li key={kw.id}>{kw.name}</li>
                            ))}
                        </ul>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
                {showModal && (
                    <div className="modal">
                        <KeywordsModal
                            selectedKeywords={selectedKeywords}
                            onConfirm={(keywords) => {
                                setSelectedKeywords(keywords);
                                setShowModal(false); // Fermer la modale après validation
                            }}
                            onCancel={() => setShowModal(false)} // Fermer la modale sans action
                        />
                    </div>
                )}
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des images</h2>
                <ul className="pictures-list">
                {pictures.map((picture, index) => (
                    <li key={`${picture.id}-${index}`} className="picture-item">
                        <div
                            className="pictureDiv"
                            onClick={() => handleEdit(index, picture.isHomepage)}
                            role="button"
                            aria-pressed={editIndex === index}
                            tabIndex={0}
                        >
                            <Image 
                                classname="imgAdmin"
                                path={picture.url}
                                alt={picture.altText}
                                title={picture.title}
                            />
                            <span>{picture.title}</span>
                            <span>Accueil ? {picture.isHomepage ? "Oui" : "Non"}</span>
                            {picture.figcaption ? <span>Légende : {picture.figcaption}</span> : null}
                            <span>Ordre : {picture.order}</span>
                        </div>
                        <div className="keywords-list">
                            {picture.keywords && picture.keywords.length > 0 ? (
                                picture.keywords.map((kw, idx) => (
                                    <span key={`${kw.id}-${idx}`} className="keyword">{kw.name}</span>
                                ))
                            ) : (
                                <span className="no-keywords">Aucun mot-clé</span>
                            )}
                        </div>
                        <button onClick={() => handleDeletePicture(index)}>Supprimer</button>
                    </li>
                ))}
                </ul>
            </div>
        </section>
    );
};

export default Pictures;
