import React from 'react'
import '../../css/components/HeaderComponents/FilterHeader.css'

/**
 * Component which render the filter inside the header
 * @returns {JSX.Element} Return the filter component
 */
export const FilterHeader = () => {
  return (
    <div className='filter_header'></div>
  )
}
