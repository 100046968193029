import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import ShipmentsModal from '../../components/AdminComponents/ShipmentsModal';

import '../../css/pages/admin/Formats.css';

const Formats = () => {
    const [formats, setFormats] = useState([]);
    const [name, setName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [shipments, setShipments] = useState([]);
    const [allShipments, setAllShipments] = useState([]);
    const [showShipmentsModal, setShowShipmentsModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFormats = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/formats/details`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des formats');
                }
                const data = await response.json();
                setFormats(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchFormats();
    }, []);

    useEffect(() => {
        const fetchShipments = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/shipments`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des types d\'envoi');
                }
                const data = await response.json();
                setAllShipments(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchShipments();
    }, []);

    const handleAddFormat = async (newFormat) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/formats/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFormat),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de l\'ajout du format');
            }

            const createdFormatId = (await response.json()).formatId;
            const createdFormat = { id: createdFormatId, ...newFormat };
            setFormats([...formats, createdFormat]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateFormat = async (index, updatedFormat) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const formatId = formats[index].id;
            const response = await fetch(`${apiUrl}/formats/${formatId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormat),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la mise à jour du format');
            }

            const updatedFormats = [...formats];
            updatedFormats[index] = { ...formats[index], ...updatedFormat };
            setFormats(updatedFormats);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteFormat = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const formatId = formats[index].id;
            const response = await fetch(`${apiUrl}/formats/${formatId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression du format');
            }

            const updatedFormats = formats.filter((_, i) => i !== index);
            setFormats(updatedFormats);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshFormats = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/formats/details`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des formats');
            const data = await response.json();
            setFormats(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des formats :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormat = {
            name,
            shipments: shipments.map(shipment => ({
                id: shipment.id
            })),
        };

        if (editIndex !== null) {
            await handleUpdateFormat(editIndex, newFormat);
        } else {
            await handleAddFormat(newFormat);
        }

        await refreshFormats();

        setName('');
        setFormats([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            // Annuler l'édition
            setName('');
            setShipments([]);
            setEditIndex(null);
        } else {
            const format = formats[index];
            setName(format.name || '');
            setShipments(format.shipments || []);
            setEditIndex(index);
        }
    };

    const openShipmentsModal = () => {
        setShowShipmentsModal(true);
    };

    const closeShipmentsModal = () => {
        setShowShipmentsModal(false);
    };

    const handleShipmentsChange = (selectedShipments) => {
        setShipments(selectedShipments);
    };

    if (loading) {
        return <div>Chargement des formats...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_formats">
            <div>
                <h1>Gestion des formats</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Nom du format</label>
                        <input
                            type="text"
                            id="name"
                            value={name || ''}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button type="button" onClick={openShipmentsModal}>Gérer les types d'envoi</button>
                        <span>{shipments.length} type(s) d'envoi sélectionné(s)</span>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des formats</h2>
                <ul>
                    {formats.map((format, index) => (
                        <li key={format.id}>
                            <div
                                className="adminFormatDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <span>{format.name}</span>
                            </div>
                            <button onClick={() => handleDeleteFormat(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>

            {showShipmentsModal && (
                <ShipmentsModal
                    associatedShipments={shipments}
                    allShipments={allShipments}
                    onClose={closeShipmentsModal}
                    onConfirm={handleShipmentsChange}
                />
            )}

        </section>
    );
};

export default Formats;
