// Projects.js

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PicturesModal from '../../components/AdminComponents/PicturesModal';

import '../../css/pages/admin/Projects.css';

const Projects = () => {

    const [projects, setProjects] = useState([]);
    const [title, setTitle] = useState('');
    const [year, setYear] = useState('');
    const [description, setDescription] = useState('');
    const [pictures, setPictures] = useState([]);
    const [allPictures, setAllPictures] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/projects/pictures`); // On récupère les projets avec leurs images
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des projets');
                }
                const data = await response.json();
                setProjects(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    useEffect(() => {
        // Charger toutes les images disponibles
        const fetchPictures = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/pictures`);
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des images');
                }
                const data = await response.json();
                setAllPictures(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchPictures();
    }, []);

    const handleAddProject = async (newProject) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/projects/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newProject),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'ajout du projet');
            }

            const createdProject = await response.json();
            setProjects([...projects, { id: createdProject.projectId, ...newProject }]);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUpdateProject = async (index, updatedProject) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const projectId = projects[index].id;
            const response = await fetch(`${apiUrl}/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProject),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour du projet');
            }

            const updatedProjects = [...projects];
            updatedProjects[index] = { ...projects[index], ...updatedProject };
            setProjects(updatedProjects);
            setEditIndex(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteProject = async (index) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const projectId = projects[index].id;
            const response = await fetch(`${apiUrl}/projects/${projectId}/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression du projet');
            }

            const updatedProjects = projects.filter((_, i) => i !== index);
            setProjects(updatedProjects);
        } catch (err) {
            setError(err.message);
        }
    };

    const refreshProjects = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/projects/pictures`);
            if (!response.ok) throw new Error('Erreur lors du rechargement des projets');
            const data = await response.json();
            setProjects(data); // Mettre à jour la liste
        } catch (err) {
            console.error('Erreur lors du rechargement des projets :', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newProject = { title, year, description, pictures: pictures.map(p => p.id) }; // Envoyer seulement les IDs des images

        if (editIndex !== null) {
            // Mise à jour du projet
            await handleUpdateProject(editIndex, newProject);
        } else {
            // Ajout d'un nouveau projet
            await handleAddProject(newProject);
        }

        await refreshProjects();

        // Réinitialiser les champs après ajout ou mise à jour
        setTitle('');
        setYear('');
        setDescription('');
        setPictures([]);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        if (editIndex !== null && index === editIndex) {
            setEditIndex(null);
            setTitle('');
            setYear('');
            setDescription('');
            setPictures([]);
        } else {
            const project = projects[index];
            setTitle(project.title || '');
            setYear(project.year || '');
            setDescription(project.description || '');
            setPictures(project.pictures || []);
            setEditIndex(index);
        }
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePicturesChange = (selectedPictures) => {
        setPictures(selectedPictures);
    };

    if (loading) {
        return <div>Chargement des projets...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <section className="admin_projects">
            <div>
                <h1>Gestion des projets</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="title">Titre</label>
                        <input
                            type="text"
                            id="title"
                            value={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="year">Année</label>
                        <input
                            type="number"
                            id="year"
                            value={year || ''}
                            onChange={(e) => setYear(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <button type="button" onClick={openModal}>Gérer les images</button>
                    </div>
                    <button type="submit">{editIndex !== null ? 'Mettre à jour' : 'Ajouter'}</button>
                </form>
                <NavLink to="/admin/dashboard">Retour à l'accueil</NavLink>
            </div>
            <div className="vertical-line"></div>
            <div>
                <h2>Liste des projets</h2>
                <ul>
                    {projects.map((project, index) => (
                        <li key={`${project.id}-${index}`}>
                            <div
                                className="adminProjectDiv"
                                onClick={() => handleEdit(index)}
                                role="button"
                                aria-pressed={editIndex === index}
                                tabIndex={0}
                            >
                                <h3>{project.title} - {project.year}</h3>
                                <p>{project.description}</p>
                            </div>
                            <button onClick={() => handleDeleteProject(index)}>Supprimer</button>
                        </li>
                    ))}
                </ul>
            </div>

            {showModal && (
                <PicturesModal
                    associatedPictures={pictures}
                    allPictures={allPictures}
                    onClose={closeModal}
                    onConfirm={handlePicturesChange}
                />
            )}
        </section>
    );
};

export default Projects;
