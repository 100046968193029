import React from 'react'
import Input from './Input';
import Textarea from './Textarea';

/**
 * Component which render a HTML form
 * @param {object} form
 * @param {string} form.className - CSS class of the form
 * @param {object[]} form.listForm - An array of objects which contains all data needed to built the form
 * @param {number} form.listForm[].id
 * @param {boolean} form.listForm[].iserror
 * @param {string} form.listForm[].name
 * @param {string} form.listForm[].placeholder
 * @param {string} form.listForm[].value
 * @param {string} form.listForm[].onchange
 * @param {string} form.onclick - Onclick function trigger when the user click on send button 
 * @returns {JSX.Element} Render the form component
 */
export default function Form({className, listForm, onclick}) {

    const inputForm = []
    const textareaForm = []

    listForm.forEach(element => {
        switch (element.tag) {
            case ("input") :
                inputForm.push(<Input key={element.id} iserror={element.iserror} name={element.name} placeholder={element.placeholder} value={element.value} onchange={element.onchange} />)
                break;
            case ("textarea") :
                textareaForm.push(<div key={element.id}><Textarea iserror={element.iserror} name={element.name} placeholder={element.placeholder} value={element.value} onchange={element.onchange} /></div>)
                break;
            default:
                console.error("Problème de formulaire. Données manquantes.")
                break;
        }
    });

    return (
        <form action="" className={className}>
            <div>
                {inputForm}
                <button type="submit" onClick={onclick}>Envoyer</button>
            </div>
            {textareaForm}
        </form>
    )
}
