import React from 'react'
import List from '../HTMLComponents/List'

import '../../css/components/HeaderComponents/NavHeader.css'

/**
 * Component which display the navigation menu
 * @returns {JSX.Element} Render the navigation menu
 */
export const NavHeader = () => {

  // Array of objects which contains all the differents routes of the website
  const pages = [
      {name: 'Accueil', url: "/"},
      {name: 'Projets', url: "/projets"},
      {name: 'Activités', url: "/activites"},
      {name: 'Boutique', url: "/boutique"},
      {name: 'Infos', url: "/infos"}
  ]

  return (
    <nav className="navHeader">
        <List lists={pages} isNav={true} />
    </nav>
  )
}
