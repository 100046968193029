import React, { useState } from 'react';

import '../../css/components/ShopComponents/ImageOverlay.css';

/**
 * Component which renders the image overlay inside the "Shop" page
 * @param {Object} props
 * @param {number} props.idProduct - The ID of the product
 * @param {function} props.handleModal - Sends to the parent component the id of the product and the format clicked by the user
 * @param {Array} props.product - The product who contains all the formats needed
 * @returns {JSX.Element} Renders the image overlay component
 */
export default function ImageOverlay({ idProduct, handleModal, product }) {

    // State to track which format is currently hovered
    const [hoveredFormat, setHoveredFormat] = useState(null);

    // Send to the "Shop" component the image ID and the format clicked by the user
    const handleClick = (formatName) => {
        handleModal(product, formatName);
    };

    // Check if the cursor is on the format
    const handleEnter = (formatName) => {
        setHoveredFormat(formatName);
    };

    // Check if the cursor is not on the format
    const handleLeave = () => {
        setHoveredFormat(null);
    };

    return (
        <div key={idProduct} className='image-overlay'>
            {product.formats && product.formats.map((format) => (
                <span
                    key={format.id}
                    onMouseEnter={() => handleEnter(format.name)}
                    onMouseLeave={handleLeave}
                    onClick={() => handleClick(format.name)}
                >
                    {hoveredFormat === format.name ? (
                        <svg className='svg-span-img-overlay'></svg>
                    ) : (
                        format.name
                    )}
                </span>
            ))}
        </div>
    );
}
