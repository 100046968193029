import React, { useEffect, useState, useCallback } from 'react'

import Header from '../components/Header'

import '../css/pages/Cart.css'
import CartItem from '../components/ShopComponents/CartItem'
import CartTotalPrice from '../components/ShopComponents/CartTotalPrice'

/**
 * Component which display the "Cart" page
 * @returns {JSX.Element}
 */
const Cart = () => {

    const [payments, setPayments] = useState([]);
    const [shipments, setShipments] = useState([]);
    const [items, setItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredShipments, setFilteredShipments] = useState([]);
    const [error, setError] = useState(null);

    // Array of object state which contains all the user data for the delivery
    const [delivery, setDelivery] = useState({
        email: "",
        country: "France",
        firstname: "",
        lastname: "",
        adress: "",
        adressopt: "",
        zip: "",
        city: "",
        phone: ""
    })
    // Array of objects which contains all the details of the delivery
    const [deliveryDetails, setDeliveryDetails] = useState({
        shipping: "",
        payment: ""
    })
    // Array of objects which contains all the card details of the user
    const [cbData, setCbData] = useState({
        number: "",
        date: "",
        crypto: ""
    })

    const fetchShipments = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/formats/details`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des modes de paiments');
            }
            const data = await response.json();
            setShipments(data);
        } catch (err) {
            console.error(err.message);
            setError(err.message);
        }
    }, []);

    useEffect(() => {
        fetchShipments();
    }, [fetchShipments]);

    const fetchPayments = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/payments`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des modes de paiments');
            }
            const data = await response.json();
            setPayments(data);
        } catch (err) {
            console.error(err.message);
            setError(err.message);
        }
    }, []);

    useEffect(() => {
        fetchPayments();
    }, [fetchPayments]);
    
    const fetchProducts = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/products/details`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des produits');
            }
            const data = await response.json();
            setProducts(data); // Store the products for CartItem and CartTotalPrice
        } catch (error) {
            console.error('Erreur : ', error);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    // When the component is render the first time, store all the item cart inside the state
    useEffect(() => {
        if(window.localStorage.getItem('cart')) {
            setItems(JSON.parse(window.localStorage.getItem('cart')))
        }
    }, [])

    useEffect(() => {
        if (!shipments.length || !items.length) return;

        // Récupérer les priorités des formats du panier
        const cartPriorities = items.map(item => {
            const format = shipments.find(f => f.name === item.format);
            return format ? Math.max(...format.shipments.map(s => s.priority)) : 0;
        });

        // Trouver la priorité maximale
        const maxPriority = Math.max(...cartPriorities);

        // Filtrer les types d'envoi compatibles avec cette priorité
        const relevantShipments = shipments
            .flatMap(format => format.shipments)
            .filter(shipment => shipment.priority >= maxPriority);

        // Supprimer les doublons et trier par priorité
        const uniqueShipments = Array.from(
            new Map(relevantShipments.map(shipment => [shipment.id, shipment])).values()
        ).sort((a, b) => a.priority - b.priority);

        setFilteredShipments(uniqueShipments);
    }, [shipments, items]);

    // Allow React to change the value of the delivery data
    const handleChangeDelivery = (e) => {
        setDelivery((prev) => ({...prev, [e.target.name]: e.target.value === "" ? "" : e.target.value }))
    }

    // Allow React to change the value of the delivery details data
    const handleChangeDeliveryDetails = (e) => {
        setDeliveryDetails((prev) => ({...prev, [e.target.name]: e.target.value === "" ? "" : e.target.value }))
    }

    // Allow React to change the value of the credit bank data
    const handleChangeCB = (e) => {
        setCbData((prev) => ({...prev, [e.target.name]: e.target.value === "" ? "" : e.target.value }))
    }

    return (
        <>
            <Header />
            <main className="cart">
                <section className="cart-datas">
                {error && <p>Erreur : {error}</p>}
                    <article className='cart-datas-contact'>
                        <h2>Contact</h2>
                        <input type="text" name="email" placeholder='Adresse e-mail' value={delivery.email} onChange={handleChangeDelivery} />
                    </article>
                    <article className='cart-datas-delivery'>
                        <h2>Livraison</h2>
                        <select name="country" value={delivery.country} onChange={handleChangeDelivery}>
                            <option value="France">France</option>
                            <option value="Belgique">Belgique</option>
                            <option value="Suisse">Suisse</option>
                        </select>
                        <div className="cart-datas-delivery-name">
                            <input type="text" name="firstname" placeholder='Prénom' value={delivery.firstname} onChange={handleChangeDelivery} />
                            <input type="text" name="lastname" placeholder='Nom' value={delivery.lastname} onChange={handleChangeDelivery} />
                        </div>
                        <input type="text" name="adress" placeholder='Adresse' value={delivery.adress} onChange={handleChangeDelivery} />
                        <input type="text" name="adressopt" placeholder='Appartement, précision (optionnel)' value={delivery.adressopt} onChange={handleChangeDelivery} />
                        <div className="cart-datas-delivery-city">
                            <input type="text" name="zip" placeholder='Code postal' value={delivery.zip} onChange={handleChangeDelivery} />
                            <input type="text" name="city" placeholder='Ville' value={delivery.city} onChange={handleChangeDelivery} />
                        </div>
                        <input type="text" name="phone" placeholder='Téléphone' value={delivery.phone} onChange={handleChangeDelivery} />
                    </article>
                    <article className="cart-datas-shipping">
                        <h2>Mode d'expédition</h2>
                        {filteredShipments.map(shipment => (
                            <div className='cart-datas-shipping-radio' key={shipment.id}>
                                <input
                                    type="radio"
                                    id={`shipment-${shipment.id}`}
                                    name="shipping"
                                    value={shipment.id}
                                    onChange={handleChangeDeliveryDetails}
                                />
                                <label htmlFor={`shipment-${shipment.id}`}>
                                    <span>{shipment.name}</span>
                                    <span>{shipment.price_ttc.toFixed(2).toString().replace('.', ',')}€</span>
                                </label>
                            </div>
                        ))}
                    </article>
                    <article className="cart-datas-payment">
                        <h2>Paiement</h2>
                        <p>Toutes les transactions sont sécurisées et chiffrées</p>
                        {payments.map(payment => payment.name).includes('Paypal') && (
                            <div className="cart-datas-shipping-radio">
                                <input type="radio" id="paypal" name="payment" value="paypal" onChange={handleChangeDeliveryDetails} />
                                <label htmlFor="paypal">Paypal</label>
                            </div>
                        )}
                        {payments.map(payment => payment.name).includes('Carte Bancaire') && (
                            <div className="cart-datas-payment-card">
                                <div className="cart-datas-shipping-radio">
                                    <input type="radio" id="card" name="payment" value="card" onChange={handleChangeDeliveryDetails} />
                                    <label htmlFor="card">Carte Bancaire</label>
                                </div>
                                <div className='cart-datas-payment-card-details'>
                                    <input type="text" name="number" placeholder='Numéro de carte' value={cbData.number} onChange={handleChangeCB} />
                                    <div className="cart-datas-payment-card-details-flex">
                                        <input type="text" name="date" placeholder="Date d'expiration" value={cbData.date} onChange={handleChangeCB} />
                                        <input type="text" name="crypto" placeholder="Crypto. visuel" value={cbData.crypto} onChange={handleChangeCB} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </article>
                </section>
                <section className="cart-details">
                    <h1 className='cart-details-title'>MON PANIER</h1>
                    {items.map(item => (
                        <CartItem
                            key={items.indexOf(item)}
                            isModalCart={false}
                            itemId={item.id}
                            format={item.format}
                            qty={item.qty}
                            setItems={setItems}
                            products={products}
                        />
                    ))}
                    <hr className='hr-cart' />
                    <CartTotalPrice
                        items={items}
                        isModalCart={false}
                        priceDelivery={
                            filteredShipments.find(s => s.id === parseInt(deliveryDetails.shipping))?.price_ttc || 0
                        }
                        paymentMethod={deliveryDetails.payment}
                        products={products}
                    />
                </section>
            </main>
        </>
    )
}

export default Cart