import React from 'react'

import "../css/components/InfosSection.css"

/**
 * Component which render a section for each infos about the owner of the website
 * @param {object} infosSection
 * @param {string} infosSection.title - Title of the section
 * @param {Object[]} infosSection.datas - All the data displaying in each section
 * @param {number} infosSection.datas.id
 * @param {number} infosSection.datas.year
 * @param {string} infosSection.datas.name
 * @param {string} infosSection.datas.location
 * @returns {JSX.Element} Render the infosSection component
 */
export default function InfosSection({title, datas}) {
    return (
        <section className='infos-details'>
            <h1>{title}</h1>
            {datas.map(data => {
                return <article key={data.id}>{data.year} - {data.name} {data.location !== undefined ? " - " + data.location : null}</article>
            })}
        </section>
    )
}
