import React from 'react'
import { NavLink } from 'react-router-dom'

/**
 * Component which render a HTML list
 * @param {object} list
 * @param {object[]} list.lists - An array of object which contains all data needed to built the list
 * @param {string} list.lists[].name
 * @param {string} list.lists[].url
 * @param {boolean} list.isNav - Switch the list mode if this is a navigation list or not
 * @returns {JSX.Element} Render the list component
 */
export default function List({lists, isNav}) {

    const li = []

    if(isNav) {
        for (const list of lists) {
            li.push(<li key={list.name}><NavLink to={list.url}>{list.name}</NavLink></li>)
        }
    } else {
        for (const list of lists) {
            li.push(<li key={list.name}>{list.name}</li>)
        }
    }

  return (
    <ul>
        {li}
    </ul>
  )
}
