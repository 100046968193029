import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Auth = () => {

    const [name, setName] = useState('');
    const [pwd, setPwd] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isConnected, setIsConnected] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (isConnected) {
            const timer = setTimeout(() => {
                navigate('/admin/dashboard');
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [isConnected, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if(!name || !pwd) {
            setError("Veuillez remplir tout les champs !");
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({name, pwd}),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.success) {
                setSuccess('Connexion réussie');
                setIsConnected(true);
            }
            else
                setError('Nom ou mot de passe incorrect');
        } catch (err) {
            console.log('Erreur lors de la requête:', err);
            setError('Une erreur est survenue lors de la connexion');
        }
    };

    return (
        <section className="auth">
            <div className="login-form">
                <h2>Connexion</h2>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {success && <p style={{color: 'green'}}>{success}</p>}

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Nom:</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={pwd} onChange={(e) => setPwd(e.target.value)} required />
                    </div>
                    <button type="submit">Se connecter</button>
                </form>
            </div>
        </section>
    )
}

export default Auth