import React, { useState, useEffect } from 'react';
import '../../css/components/AdminComponents/ShipmentsModal.css';

const ShipmentsModal = ({ associatedShipments, allShipments, onClose, onConfirm }) => {
    const [leftShipments, setLeftShipments] = useState([]);
    const [rightShipments, setRightShipments] = useState([]);
    const [isLeftDragOver, setIsLeftDragOver] = useState(false);
    const [isRightDragOver, setIsRightDragOver] = useState(false);

    useEffect(() => {
        setLeftShipments(associatedShipments || []);
        const associatedIds = (associatedShipments || []).map(f => f.id);
        const unassociatedShipments = allShipments.filter(f => !associatedIds.includes(f.id));
        setRightShipments(unassociatedShipments);
    }, [associatedShipments, allShipments]);

    const handleDragStart = (e, shipment, fromLeft) => {
        e.dataTransfer.setData('shipment', JSON.stringify(shipment));
        e.dataTransfer.setData('fromLeft', fromLeft);
    };

    const handleDrop = (e, targetLeft) => {
        e.preventDefault();
        const shipment = JSON.parse(e.dataTransfer.getData('shipment'));
        const fromLeft = e.dataTransfer.getData('fromLeft') === 'true';

        if (fromLeft && !targetLeft) {
            setLeftShipments(leftShipments.filter(f => f.id !== shipment.id));
            setRightShipments([...rightShipments, shipment]);
        } else if (!fromLeft && targetLeft) {
            setRightShipments(rightShipments.filter(f => f.id !== shipment.id));
            setLeftShipments([...leftShipments, { ...shipment}]);
        }

        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(true);
        } else {
            setIsRightDragOver(true);
        }
    };

    const handleDragLeave = (e, targetLeft) => {
        e.preventDefault();
        if (targetLeft) {
            setIsLeftDragOver(false);
        } else {
            setIsRightDragOver(false);
        }
    };

    const handleConfirm = () => {
        onConfirm(leftShipments);
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Gérer les types d'envoi</h2>
                <div className="modal-body">
                    <div
                        className={`shipment-list left ${isLeftDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, true)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, true)}
                        onDragLeave={(e) => handleDragLeave(e, true)}
                    >
                        <h3>Types d'envoi associés</h3>
                        <div className="shipments-container">
                            {leftShipments.map((shipment) => (
                                <div
                                    key={shipment.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, shipment, true)}
                                    className="shipment-item"
                                >
                                    <span>{shipment.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`shipment-list right ${isRightDragOver ? 'drag-over' : ''}`}
                        onDrop={(e) => handleDrop(e, false)}
                        onDragOver={allowDrop}
                        onDragEnter={(e) => handleDragEnter(e, false)}
                        onDragLeave={(e) => handleDragLeave(e, false)}
                    >
                        <h3>Types d'envoi disponibles</h3>
                        <div className="shipments-container">
                            {rightShipments.map((shipment) => (
                                <div
                                    key={shipment.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, shipment, false)}
                                    className="shipment-item"
                                >
                                    <span>{shipment.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={handleConfirm}>Valider</button>
                    <button onClick={onClose}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default ShipmentsModal;
