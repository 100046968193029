import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import { ShopHeader } from '../components/HeaderComponents/ShopHeader';
import Carousel from '../components/ShopComponents/Carousel';
import { CartModalShop } from '../components/ShopComponents/CartModalShop';

import '../css/pages/Product.css';

/**
 * Page produit qui affiche tous les détails d'un produit spécifique
 * @returns La page produit
 */
export const Product = () => {
    const { id } = useParams();

    const [isModalCartDisplay, setIsModalCartDisplay] = useState(false);
    const [items, setItems] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [isOriginalClicked, setIsOriginalClicked] = useState(false);
    const [format, setFormat] = useState('');
    const [stock, setStock] = useState(1);
    const formatsRef = useRef(null);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [stockWarning, setStockWarning] = useState('');

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await fetch(`${apiUrl}/products/${id}/details`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération du produit');
                }
                const data = await response.json();
                setProduct(data);
            } catch (err) {
                console.error(err.message);
                setError(err.message);
            }
        };
        fetchProduct();
    }, [id]);

    const handleClickFormat = (e) => {
        const formatName = e.target.innerText;
        const selected = product.formats.find(f => f.name === formatName);
        setFormat(selected.name);
        setStock(selected.stock);
        setStockWarning('');
        setQuantity(1);
        setIsOriginalClicked(selected.name === 'Original');

        let childs = [].slice.call(formatsRef.current.children);
        childs.forEach(child => child.classList.remove('button-clicked'));
        e.target.classList.add('button-clicked');
    };

    const handleClickMinus = () => {
        setQuantity(quantity > 1 ? quantity - 1 : quantity);
        setStockWarning('');
    };

    const handleClickPlus = () => {
        if (quantity < stock) {
            setQuantity(quantity + 1);
            setStockWarning('');
        } else {
            setStockWarning('Vous ne pouvez pas excéder le stock actuel du produit.');
            setTimeout(() => {
                setStockWarning('');
            }, 5000);
        }
    };

    const displayModal = () => {
        setIsModalCartDisplay(prev => !prev);
    };

    const handleClickCart = () => {
        if (!product || !format) {
            setStockWarning('Veuillez sélectionner un format avant d’ajouter au panier.');
            setTimeout(() => {
                setStockWarning('');
            }, 5000);
            return;
        }
    
        const item = {
            id: product.id,
            format: format,
            qty: isOriginalClicked ? 1 : quantity,
        };
    
        let cart = [];
        if (window.localStorage.getItem('cart')) {
            cart = JSON.parse(window.localStorage.getItem('cart'));
        }
    
        let currentQtyInCart = 0;
    
        // Calculer la quantité totale déjà présente dans le panier pour ce produit et ce format
        cart.forEach((element) => {
            if (element.id === item.id && element.format === item.format) {
                currentQtyInCart += element.qty;
            }
        });
    
        // Si la quantité totale dépasse le stock, ajuster la quantité ajoutée
        const totalQty = currentQtyInCart + item.qty;
        if (totalQty > stock) {
            const remainingStock = stock - currentQtyInCart;
            if (remainingStock > 0) {
                item.qty = remainingStock; // Ajuster la quantité pour correspondre au stock restant
            } else {
                setStockWarning('Stock insuffisant pour ajouter ce produit.');
                setTimeout(() => {
                    setStockWarning('');
                }, 5000);
                return;
            }
        }
    
        // Mettre à jour le panier
        let isCartUpdated = false;
        cart.forEach((element) => {
            if (element.id === item.id && element.format === item.format) {
                element.qty += item.qty;
                isCartUpdated = true;
            }
        });
    
        if (!isCartUpdated) {
            cart.push(item);
        }
    
        window.localStorage.setItem('cart', JSON.stringify(cart));
        setItems(cart); // Synchroniser l'état avec les données mises à jour
        displayModal();
    };

    return (
        <>
            <Header />
            <main className='product'>
                <section className="product-container">
                    {error && <p>Erreur : {error}</p>}
                    {product ? (
                        <>
                            <article className='product-image'>
                                <Carousel idProduct={product.id} productTitle={product.name} />
                            </article>
                            <article className='product-details'>
                                <h1>{product.name}</h1><br />
                                <span>{product.price_ttc.toLocaleString('fr-FR', { style: "currency", currency: 'EUR' })}</span>
                                <div ref={formatsRef} className='product-details-formats'>
                                    {product.formats && product.formats.map((formatItem, index) => (
                                        <button
                                            key={index}
                                            className='product-details-format'
                                            onClick={handleClickFormat}
                                        >
                                            {formatItem.name}
                                        </button>
                                    ))}
                                </div>
                                {stock === 0 ? (
                                    <p className="product-details-empty">Rupture de stock</p>
                                ) : (
                                    !isOriginalClicked && (
                                        <div className='product-details-quantity'>
                                            <button onClick={handleClickMinus}>-</button>
                                            <input type="text" value={quantity} disabled />
                                            <button onClick={handleClickPlus}>+</button>
                                        </div>
                                    )
                                )}
                                {stockWarning && <span className="product-stock-warning">{stockWarning}</span>}
                                <button productid={product.id} className='product-add-cart' onClick={handleClickCart}>Ajouter au panier</button>
                            </article>
                            <article className='product-desc'>
                                <p>{product.description}</p>
                            </article>
                        </>
                    ) : (
                        !error && <p>Chargement du produit...</p>
                    )}
                </section>
                {isModalCartDisplay &&
                    <CartModalShop onClickParent={displayModal} cartItems={items} setItems={setItems} />
                }
            </main>
            <ShopHeader />
        </>
    );
};
